import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsAccessor, SettingsService } from '@trumpf-xguide/xguide';
import { map } from 'rxjs/operators';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../../../shared/constants';
import { TranslationHelper } from '../../../shared/helpers';
import { AppSettings, SystemOfUnits } from '../../../shared/types';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { materialToTranslation } from '../../helpers/enum-to-translation';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { SheetWeightCalculationService } from '../../services/sheet-weight-calculation.service';

@Component({
  selector: 'lsb-sheet-weight',
  templateUrl: './sheet-weight.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './sheet-weight.component.scss'],
})
export class SheetWeightComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STEEL;

  public length: number = 1;
  public width: number = 1;
  public sheetThickness: number = 1;

  public calculationError$ = this.calculationService.calculationResult$.pipe(map((r) => r?.error));

  private settings: SettingsAccessor<AppSettings>;

  constructor(
    public translations: TranslationHelper,
    public calculationService: SheetWeightCalculationService,
    private translateService: TranslateService,
    private settingsService: SettingsService,
  ) {
    for (const material of Object.values(Material)) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }

    this.settings = settingsService.access<AppSettings>(
      SHARED_SETTINGS_NAMESPACE,
      DEFAULT_APP_SETTINGS,
    );
  }

  private get systemOfUnits(): SystemOfUnits {
    return this.settings.get('unitSystem');
  }

  ngOnInit(): void {
    // if the unit system is set to imperial the default values will be set to 25.4 mm which equals 1 inch.
    if (this.systemOfUnits === SystemOfUnits.Imperial) {
      this.length = 25.4;
      this.width = 25.4;
      this.sheetThickness = 25.4;
    }
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedMaterial,
      this.length,
      this.width,
      this.sheetThickness,
    );
  }

  public selectedMaterialName(): string {
    return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }
}
