import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { TranslationHelper } from '../../../shared/helpers';
import {
  applicationTypeToTranslation,
  materialToTranslation,
} from '../../helpers/enum-to-translation';
import { TranslateService } from '@ngx-translate/core';
import { PrePunchingDiameterCalculationService } from '../../services/pre-punching-diameter-calculation.service';
import { map, tap } from 'rxjs/operators';
import { ApplicationType } from '../../model/application-type';
import { ThreadSizeType } from '../../model/pre-punching-diameter-type';

@Component({
  selector: 'lsb-pre-punching-diameter',
  templateUrl: './pre-punching-diameter.component.html',
  styleUrls: ['./pre-punching-diameter.component.scss'],
})
export class PrePunchingDiameterComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public applicationType = ApplicationType;

  public applications: DropdownItemValue<ApplicationType>[] = [];
  public selectedApplication: ApplicationType = ApplicationType.COUNTERSINK_UPPER;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STEEL;

  public threadSizes$ = this.calculationService.threadSizes$.pipe(
    tap((s) => {
      this.threadSizeChanged(s[0]);
      this.cdr.detectChanges();
    }),
  );
  public selectedThreadSize: ThreadSizeType = 'F 8';

  public sheetThicknesses$ = this.calculationService.thicknesses$.pipe(
    tap((t) => {
      this.selectedSheetThickness = t[0];
      this.cdr.detectChanges();
    }),
  );
  public selectedSheetThickness = 0;

  public calculationResult$ = this.calculationService.calculationResult$;
  public calculationError$ = this.calculationResult$.pipe(map((r) => r?.error));

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: PrePunchingDiameterCalculationService,
    private cdr: ChangeDetectorRef,
  ) {
    for (const material of Object.values(Material)) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }

    for (const application of Object.values(ApplicationType)) {
      this.applications.push({
        id: application,
        name: applicationTypeToTranslation(application, translations, translateService),
      });
    }
  }

  ngOnInit(): void {
    this.applicationChanged(ApplicationType.COUNTERSINK_UPPER);
  }

  public selectedMaterialName(): string {
    return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
  }

  public selectedApplicationName(): string {
    return this.applications.find((m) => m.id === this.selectedApplication)?.name ?? 'unknown';
  }

  public applicationChanged(newApplication: ApplicationType): void {
    this.selectedApplication = newApplication;
    this.calculationService.applicationOrMaterialChanged(
      this.selectedApplication,
      this.selectedMaterial,
    );
  }

  public materialChanged(newMaterial: Material): void {
    this.selectedMaterial = newMaterial;
    this.calculationService.applicationOrMaterialChanged(
      this.selectedApplication,
      this.selectedMaterial,
    );
    this.calculationService.threadSizeOrMaterialChanged(
      this.selectedApplication,
      this.selectedMaterial,
      this.selectedThreadSize,
    );
  }

  public threadSizeChanged(newThreadSize: ThreadSizeType): void {
    this.selectedThreadSize = newThreadSize;
    this.calculationService.threadSizeOrMaterialChanged(
      this.selectedApplication,
      this.selectedMaterial,
      this.selectedThreadSize,
    );
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedApplication,
      this.selectedMaterial,
      this.selectedThreadSize,
      this.selectedSheetThickness,
    );
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }
}
