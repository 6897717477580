import { Injectable } from '@angular/core';
import { SettingsAccessor, SettingsService } from '@trumpf-xguide/xguide';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../../shared/constants';
import { AppSettings, SystemOfUnits } from '../../shared/types';
import { calculateOuterCircleDiameter, convertMillimeterTo } from '../helpers/calculations-helper';
import { DimensionInterval } from '../model/dimension-interval';
import { Dimensions } from '../model/dimensions';
import { Geometry } from '../model/geometry';

@Injectable({
  providedIn: 'root',
})
export class DimensionsValidatorService {
  private settings: SettingsAccessor<AppSettings>;
  constructor(settingsService: SettingsService) {
    this.settings = settingsService.access<AppSettings>(
      SHARED_SETTINGS_NAMESPACE,
      DEFAULT_APP_SETTINGS,
    );
  }

  public validate(dimensions: Dimensions): string | undefined {
    let value: number;
    let valueLabel: 'a' | 'b' | 'd' | 'l' | 'Outer circle diameter';
    let interval: DimensionInterval;
    let height: number | undefined;
    let width: number | undefined;
    let widthLabel: 'b' | 'l' = 'b';
    switch (dimensions.geometry) {
      case Geometry.ROUND:
        value = dimensions.d;
        valueLabel = 'd';
        interval = { min: 1, max: 76.2 };
        break;
      case Geometry.SQUARE:
        value = dimensions.a;
        valueLabel = 'a';
        interval = { min: 1, max: 53.8 };
        break;
      case Geometry.RECTANGLE:
        value = calculateOuterCircleDiameter(dimensions);
        valueLabel = 'Outer circle diameter';
        interval = { min: 1.8, max: 76.2 };
        height = dimensions.a;
        width = dimensions.b;
        widthLabel = 'b';
        break;
      case Geometry.OBLONG:
        value = calculateOuterCircleDiameter(dimensions);
        valueLabel = 'l';
        interval = { min: 1, max: 76.2 };
        height = dimensions.a;
        width = dimensions.l;
        widthLabel = 'l';
        break;
    }
    const intervalError = this.checkInterval(value, interval, valueLabel);
    if (intervalError) {
      return intervalError;
    }
    if (height && width && +height >= +width) {
      return `Dimension a should be less then dimension ${widthLabel}`;
    }

    return undefined;
  }

  checkInterval(
    value: number,
    interval: DimensionInterval,
    valueLabel: string,
  ): string | undefined {
    if (value < interval.min || value > interval.max) {
      const unitSystem = this.settings.get('unitSystem');
      return `Dimension ${valueLabel} should be between ${convertMillimeterTo(
        interval.min,
        unitSystem,
      )} and ${convertMillimeterTo(interval.max, unitSystem)}${
        unitSystem === SystemOfUnits.Metric ? 'mm' : 'inch'
      }`;
    }
    return undefined;
  }
}
