export enum PunchSize {
  MULTI_TOOL_5_STATION = 'MULTI_TOOL_5_STATION',
  MULTI_TOOL_10_STATION = 'MULTI_TOOL_10_STATION',
  SIZE_0_6MM = 'SIZE_0_6MM',
  SIZE_0_10POINT5MM = 'SIZE_0_10POINT5MM',
  SIZE_1 = 'SIZE_1',
  SIZE_2 = 'SIZE_2',
  SIZE_2_ENFORCED_SHOULDER = 'SIZE_2_ENFORCED_SHOULDER',
  NOT_POSSIBLE = 'NOT_POSSIBLE',
}
