import { PunchingMachineType } from '../model/punching-machine';

export const MAX_SHEET_THICKNESSES: Map<PunchingMachineType, number> = new Map<
  PunchingMachineType,
  number
>([
  [PunchingMachineType.TRU_PUNCH_1000_S05, 6.4],
  [PunchingMachineType.TRU_PUNCH_1000_S19, 6.4],
  [PunchingMachineType.TRU_PUNCH_2000_S18, 6.4],
  [PunchingMachineType.TRU_PUNCH_3000_S11, 6.4],
  [PunchingMachineType.TRU_PUNCH_3000_S20, 6.4],
  [PunchingMachineType.TRU_PUNCH_5000_S01, 8],
  [PunchingMachineType.TRU_PUNCH_5000_S10, 8],
  [PunchingMachineType.TRU_PUNCH_5000_S12, 8],
  [PunchingMachineType.TRU_MATIC_1000_K07, 6.4],
  [PunchingMachineType.TRU_MATIC_3000_K04, 6.4],
  [PunchingMachineType.TRU_MATIC_3000_K09, 6.4],
  [PunchingMachineType.TRU_MATIC_6000_K01, 8],
  [PunchingMachineType.TRU_MATIC_6000_K05, 6.4],
  [PunchingMachineType.TRU_MATIC_6000_K06, 6.4],
  [PunchingMachineType.TRU_MATIC_7000_K02, 8],
  [PunchingMachineType.TRU_MATIC_7000_K08, 8],
]);
