import { PunchingMachine, PunchingMachineType } from '../model/punching-machine';

export const PUNCHING_MACHINES: PunchingMachine[] = [
  { type: PunchingMachineType.TRU_PUNCH_1000_S05, name: 'TruPunch 1000 (S05)' },
  { type: PunchingMachineType.TRU_PUNCH_1000_S19, name: 'TruPunch 1000 (S19)' },
  { type: PunchingMachineType.TRU_PUNCH_2000_S18, name: 'TruPunch 2000 (S18)' },
  { type: PunchingMachineType.TRU_PUNCH_3000_S11, name: 'TruPunch 3000 (S11)' },
  { type: PunchingMachineType.TRU_PUNCH_3000_S20, name: 'TruPunch 3000 (S20)' },
  { type: PunchingMachineType.TRU_PUNCH_5000_S01, name: 'TruPunch 5000 (S01)' },
  { type: PunchingMachineType.TRU_PUNCH_5000_S10, name: 'TruPunch 5000 (S10)' },
  { type: PunchingMachineType.TRU_PUNCH_5000_S12, name: 'TruPunch 5000 (S12)' },
  { type: PunchingMachineType.TRU_MATIC_1000_K07, name: 'TruMatic 1000 (K07)' },
  { type: PunchingMachineType.TRU_MATIC_3000_K04, name: 'TruMatic 3000 (K04)' },
  { type: PunchingMachineType.TRU_MATIC_3000_K09, name: 'TruMatic 3000 (K09)' },
  { type: PunchingMachineType.TRU_MATIC_6000_K01, name: 'TruMatic 6000 (K01)' },
  { type: PunchingMachineType.TRU_MATIC_6000_K05, name: 'TruMatic 6000 (K05)' },
  { type: PunchingMachineType.TRU_MATIC_6000_K06, name: 'TruMatic 6000 (K06)' },
  { type: PunchingMachineType.TRU_MATIC_7000_K02, name: 'TruMatic 7000 (K02)' },
  { type: PunchingMachineType.TRU_MATIC_7000_K08, name: 'TruMatic 7000 (K08)' },
];

export const GAUGES: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
];
