import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { calculationTypeToTranslation } from '../../helpers/enum-to-translation';
import { CalculationType } from '../../model/calculation-type';
import { EnrichedCalculationType } from '../../model/enriched-calculation-type';

@Component({
  selector: 'lsb-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.scss'],
})
export class CalculationsComponent {
  public readonly uiElementIds = UiElementIds;
  public calculationTypes: EnrichedCalculationType[] = [];
  public selectedCalculationType: CalculationType = CalculationType.PUNCHING_FORCE;
  // provides enum reference in template
  public allCalculationType = CalculationType;

  constructor(public translations: TranslationHelper, private translateService: TranslateService) {
    for (const calculationType of Object.values(CalculationType)) {
      const enrichedCalcType = {
        type: calculationType,
        translation: calculationTypeToTranslation(calculationType, translations, translateService),
        route: calculationType.toLowerCase().replace(/_/g, '-'),
      };
      this.calculationTypes.push(enrichedCalcType);
    }
  }

  public selectCalculationType(calculationType: CalculationType) {
    this.selectedCalculationType = calculationType;
  }
}
