import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Material } from '../model/material';
import { SheetWeightCalculationResult } from '../model/sheet-weight-calculation-result';

@Injectable({
  providedIn: 'root',
})
export class SheetWeightCalculationService {
  private calculationResult: BehaviorSubject<SheetWeightCalculationResult | undefined> =
    new BehaviorSubject<SheetWeightCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  constructor() {}

  public calculate(
    material: Material,
    length: number,
    width: number,
    sheetThickness: number,
  ): void {
    let errorMessage = undefined;
    let sheetWeight: number = 0;

    let materialWeightPerCubicMeter = 0;

    switch (material) {
      case Material.STEEL:
        materialWeightPerCubicMeter = 7.85 * 1000;
        break;
      case Material.STAINLESS_STEEL:
        materialWeightPerCubicMeter = 7.9 * 1000;
        break;
      case Material.ALUMINUM:
        materialWeightPerCubicMeter = 2.71 * 1000;
        break;
    }

    const volumeInCubicMeters = (length / 1000) * (width / 1000) * (sheetThickness / 1000);
    sheetWeight = volumeInCubicMeters * materialWeightPerCubicMeter;

    this.calculationResult.next({
      label: 'CalculationDone!',
      error: errorMessage,
      sheetWeight: sheetWeight,
    });
  }

  public clearResult(): void {
    this.calculationResult.next(undefined);
  }
}
