<div class="input-container">
  <div class="stacked">

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.APPLICATION | translate }}
    </span>
    <xui-dropdown #applicationDropDown class="drop-down" [label]="selectedApplicationName()"
                  data-ngtx="pre-punching-diameter:application">
      <xui-dropdown-item
        *ngFor="let application of applications"
        (click)="applicationChanged(application.id); clearResult(); applicationDropDown.close()"
      >
        {{ application?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
    </span>
    <xui-dropdown #materialDropDown class="drop-down" [label]="selectedMaterialName()"
                  data-ngtx="pre-punching-diameter:material">
      <xui-dropdown-item
        *ngFor="let material of materials"
        (click)="materialChanged(material.id); clearResult(); materialDropDown.close()"
      >
        {{ material?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.THREAD_SIZE | translate }}
    </span>
    <xui-dropdown #threadSizeDropDown class="drop-down" [label]="selectedThreadSize"
                  data-ngtx="pre-punching-diameter:threadSize">
      <xui-dropdown-item
        *ngFor="let threadSize of threadSizes$ | async"
        (click)="threadSizeChanged(threadSize); clearResult(); threadSizeDropDown.close()"
      >
        {{ threadSize }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}
    </span>
    <xui-dropdown #sheetThicknessDropDown class="drop-down" [label]="selectedSheetThicknessInCmOrInch.value"
                  data-ngtx="pre-punching-diameter:sheet-thickness">

      <xui-value #selectedSheetThicknessInCmOrInch  [binding]="selectedSheetThickness">
        <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
        <xui-number-formatter [maxDecimals]="2" [unit]="selectedSheetThicknessInCmOrInch.unit"></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        *ngFor="let sheetThickness of sheetThicknesses$ | async"
        (click)="selectedSheetThickness = sheetThickness; clearResult(); sheetThicknessDropDown.close()"
      >
        <xui-value #sheetThicknessInCentimeterOrInch [binding]="sheetThickness">
          <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
          <xui-number-formatter [maxDecimals]="2" [unit]="sheetThicknessInCentimeterOrInch.unit"></xui-number-formatter>
        </xui-value>
        {{ sheetThicknessInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>

    <div class="applicationImage">
      <img height="152.4px" width="291.9px" src="../../../assets/img/calculations/countersinkUpper.png" *ngIf="selectedApplication === applicationType.COUNTERSINK_UPPER" >
      <img height="145.2px" width="285.6px" src="../../../assets/img/calculations/countersinkUnder.png" *ngIf="selectedApplication === applicationType.COUNTERSINK_UNDER" >
      <img height="100.2px" width="317.4px" src="../../../assets/img/calculations/tappingExtrusion.png" *ngIf="selectedApplication === applicationType.EXTRUSION" >
      <img height="68.4px" width="258px" src="../../../assets/img/calculations/tappingEven.png" *ngIf="selectedApplication === applicationType.TAPPING_EVEN" >
    </div>

    <span class="calculationError" *ngIf="calculationError$ | async as error">
      {{error}}
    </span>

    <xui-button
      class="calculate-button"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_PRE_PUNCHING_DIAMETER }}"
      (click)="calculate()"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>
  <div class="results-container" >
    <div class="stacked" *ngIf="calculationResult$ | async as calculationResult">
      <span class="calculationResultTitle" *ngIf="!calculationResult.error">
        {{ translations.CALCULATIONS.TYPES.PRE_PUNCHING_DIAMETER | translate }}:
      </span>
      <ng-container *ngIf="!calculationResult.error && calculationResult.diameter">
        <xui-caption-label
          [caption]="'Diameter'"
          [label]="diameterValue.value"
          class="resultLabel"
        >
          <xui-value #diameterValue [binding]="calculationResult.diameter">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="diameterValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>
    </div>
  </div>
</div>
