import { Dimensions } from '../model/dimensions';
import { Geometry } from '../model/geometry';
import { SystemOfUnits } from '../../shared/types';
import { cmInchFactor } from '../../shared/constants';

export const calculateOuterCircleDiameter = (dimensions: Dimensions): number => {
  let diameter = 1;

  switch (dimensions.geometry) {
    case Geometry.ROUND:
      diameter = dimensions.d;
      break;
    case Geometry.OBLONG:
      diameter = dimensions.l;
      break;
    case Geometry.SQUARE:
      diameter = Math.sqrt(2 * (dimensions.a * dimensions.a));
      break;
    case Geometry.RECTANGLE:
      diameter = Math.sqrt(dimensions.b * dimensions.b + dimensions.a * dimensions.a);
      break;
  }

  return diameter;
};

export const convertMillimeterTo = (value: number, unitSystem: SystemOfUnits): string => {
  if (unitSystem === SystemOfUnits.Metric) {
    return value.toString();
  }
  return (value / 10 / cmInchFactor).toFixed(3);
};
