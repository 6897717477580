<div class="input-container">
  <div class="stacked">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.MACHINE | translate }}
    </span>
    <xui-dropdown #machineDropDown class="drop-down" [label]="selectedMachineName()"
                  data-ngtx="punching-force:machine">
      <xui-dropdown-item
        *ngFor="let machine of machines"
        (click)="selectMachine(machine.type); machineDropDown.close(); clearResult()"
      >
        {{ machine?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
    </span>
    <xui-dropdown #materialDropDown class="drop-down" [label]="selectedMaterialName()"
                  data-ngtx="punching-force:material">
      <xui-dropdown-item
        *ngFor="let material of materials"
        (click)="selectedMaterial = material.id; materialDropDown.close(); clearResult()"
      >
        {{ material?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}
    </span>
    <xui-dropdown #sheetThicknessDropDown class="drop-down" [label]="selectedSheetThicknessInCmOrInch.value"
                  data-ngtx="punching-force:sheet-thickness">
                  
      <xui-value #selectedSheetThicknessInCmOrInch  [binding]="selectedSheetThickness">
      <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
      <xui-number-formatter [maxDecimals]="2" [unit]="selectedSheetThicknessInCmOrInch.unit"></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        *ngFor="let sheetThickness of availableSheetThicknesses"
        (click)="selectedSheetThickness = sheetThickness; sheetThicknessDropDown.close(); clearResult()"
      >
      <xui-value #sheetThicknessInCentimeterOrInch [binding]="sheetThickness">
        <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
        <xui-number-formatter [maxDecimals]="2" [unit]="sheetThicknessInCentimeterOrInch.unit"></xui-number-formatter>
        </xui-value>
        {{ sheetThicknessInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.DIMENSIONS | translate }}
    </span>

    <lsb-dimensions [(dimensions)]="dimensions" [geometries]="availableGeometries" (change)="clearResult()"></lsb-dimensions>

    <span class="calculationError" *ngIf="calculationError$ | async as error">
      {{error}}
    </span>
    <div class="button-area">
      <xui-button
        class="calculate-button"
        xuiClickUsageTracking="{{ uiElementIds.CALCULATE_PUNCHING_FORCE }}"
        (click)="calculate()"
      >
        {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
      </xui-button>
    </div>
  </div>

</div>
<div  class="results-container" >
  <div *ngIf="calculationService.calculationResult$ | async as calculationResult">  
    <div *ngIf="!calculationResult.error" >

    <span class="calculationResultTitle">{{ translations.CALCULATIONS.CALCULATED_PUNCHING_FORCE_BY_SHEAR | translate}}</span>
    <div class="punching-force" >
      <div class="calculation-result-label">      
        <xui-caption-label [caption]="translations.CALCULATIONS.SHEAR_FACTOR.FLAT | translate">
          <xui-value
          #flatPunchingForceValue
          [(binding)]="calculationResult.flatPunchingForce"
          [fallbackValue]="0"
          >    
          <xui-number-formatter [maxDecimals]="2" [unit]="'kN'"></xui-number-formatter>
        </xui-value>
        <img class="shear-factor-img" src="../../../../assets/img/calculations/flat.png" >
        {{ flatPunchingForceValue.value}}
      </xui-caption-label>
      <p *ngIf="calculationResult.flatPunchingForceErrorMessage" class="calculationError">{{calculationResult.flatPunchingForceErrorMessage}}</p>
        
      </div>
      <div class="calculation-result-label">
        <xui-caption-label [caption]="translations.CALCULATIONS.PUNCH | translate" *ngIf="recommendedFlatPunchName$ | async as punchName" >
          <p *ngIf="calculationResult.flatPunchErrorMessage" class="calculationError">{{calculationResult.flatPunchErrorMessage}}</p>
          <p *ngIf="!calculationResult.flatPunchErrorMessage">{{punchName}}</p>
        </xui-caption-label>
      </div>
      <div class="calculation-result-label">
        <xui-caption-label [caption]="translations.CALCULATIONS.DIE | translate" *ngIf="recommendedFlatDieName$ | async as dieName" >
          <p *ngIf="calculationResult.flatDieErrorMessage" class="calculationError">{{calculationResult.flatDieErrorMessage}}</p>
          <p *ngIf="!calculationResult.flatDieErrorMessage">{{dieName}}</p>
        </xui-caption-label>
      </div>
    </div>

    <div class="punching-force">
      <div class="calculation-result-label">
            <xui-caption-label [caption]="translations.CALCULATIONS.SHEAR_FACTOR.WHISPER | translate">
              <xui-value
                #whisperPunchingForceValue
                [(binding)]="calculationResult.whisperPunchingForce"
                [fallbackValue]="0"
              >
                <xui-number-formatter [maxDecimals]="2" [unit]="'kN'"></xui-number-formatter>
              </xui-value>
              <img class="shear-factor-img" src="../../../../assets/img/calculations/whisper.png" >
                {{ whisperPunchingForceValue.value}}
            </xui-caption-label>
            <p *ngIf="calculationResult.whisperPunchingForceErrorMessage" class="calculationError">{{calculationResult.whisperPunchingForceErrorMessage}}</p>
      </div>
      <div class="calculation-result-label">
        <xui-caption-label [caption]="translations.CALCULATIONS.PUNCH | translate" *ngIf="recommendedWhisperPunchName$ | async as punchName" >
          <p *ngIf="calculationResult.whisperPunchErrorMessage" class="calculationError">{{calculationResult.whisperPunchErrorMessage}}</p>
          <p *ngIf="!calculationResult.whisperPunchErrorMessage">{{punchName}}</p>
        </xui-caption-label>
      </div>
        <div class="calculation-result-label">
        <xui-caption-label [caption]="translations.CALCULATIONS.DIE | translate" *ngIf="recommendedWhisperDieName$ | async as dieName" >
          <p *ngIf="calculationResult.whisperDieErrorMessage" class="calculationError">{{calculationResult.whisperDieErrorMessage}}</p>
          <p *ngIf="!calculationResult.whisperDieErrorMessage">{{dieName}}</p>
        </xui-caption-label>
      </div>
    </div>
    
    <div class="punching-force">
      <div class="calculation-result-label">
      <xui-caption-label [caption]="translations.CALCULATIONS.SHEAR_FACTOR.ROOF | translate">
          <xui-value
            #roofPunchingForceValue
            [(binding)]="calculationResult.roofPunchingForce"
            [fallbackValue]="0"
          >
            <xui-number-formatter [maxDecimals]="2" [unit]="'kN'"></xui-number-formatter>
          </xui-value>
          <img class="shear-factor-img" src="../../../../assets/img/calculations/roof.png">
            {{ roofPunchingForceValue.value}}
        </xui-caption-label>
        <p *ngIf="calculationResult.roofPunchingForceErrorMessage" class="calculationError">{{calculationResult.roofPunchingForceErrorMessage}}</p>
      </div>
      <div class="calculation-result-label">
        <xui-caption-label [caption]="translations.CALCULATIONS.PUNCH | translate" *ngIf="recommendedRoofPunchName$ | async as punchName" >
          <p *ngIf="calculationResult.roofPunchErrorMessage" class="calculationError">{{calculationResult.roofPunchErrorMessage}}</p>
          <p *ngIf="!calculationResult.roofPunchErrorMessage">{{punchName}}</p>
        </xui-caption-label>
      </div>
        <div class="calculation-result-label">
        <xui-caption-label [caption]="translations.CALCULATIONS.DIE | translate" *ngIf="recommendedRoofDieName$ | async as dieName" >
          <p *ngIf="calculationResult.roofDieErrorMessage" class="calculationError">{{calculationResult.roofDieErrorMessage}}</p>
          <p *ngIf="!calculationResult.roofDieErrorMessage">{{dieName}}</p>
        </xui-caption-label>
      </div>
    </div>
    

    <!-- <span class="calculationResultTitle">{{ translations.CALCULATIONS.RECOMMENDATION_TRUMPF | translate}}</span>
    <div class="recommendation">
      <xui-caption-label [caption]="translations.CALCULATIONS.PUNCH | translate" *ngIf="recommendedFlatPunchName$ | async as punchName" >
        <p *ngIf="calculationResult.punchErrorMessage" class="calculationError">{{calculationResult.punchErrorMessage}}</p>
        <p *ngIf="!calculationResult.punchErrorMessage">{{punchName}}</p>
      </xui-caption-label>
      <xui-caption-label [caption]="translations.CALCULATIONS.DIE | translate" *ngIf="recommendedFlatDieName$ | async as dieName" >
        <p *ngIf="calculationResult.dieErrorMessage" class="calculationError">{{calculationResult.dieErrorMessage}}</p>
        <p *ngIf="!calculationResult.dieErrorMessage">{{dieName}}</p>
      </xui-caption-label>
    </div>   -->
  </div>
</div>
