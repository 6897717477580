import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { TranslationHelper } from '../../shared/helpers';
import { calculateOuterCircleDiameter } from '../helpers/calculations-helper';
import { DieSize } from '../model/die-size';
import { Dimensions } from '../model/dimensions';
import { Geometry } from '../model/geometry';
import { Material } from '../model/material';
import { PunchSize } from '../model/punch-size';
import { PunchingForceCalculationResult } from '../model/punching-force-calculation-result';
import { PunchingMachineType } from '../model/punching-machine';
import { ShearFactor } from '../model/shear-factor';
import { DimensionsValidatorService } from './dimensions-validator.service';

@Injectable({
  providedIn: 'root',
})
export class PunchingForceCalculationService {
  private readonly roofShearFactorValues: Map<number, number> = new Map<number, number>([
    [1, 1.53],
    [1.25, 1.49],
    [1.5, 1.44],
    [1.75, 1.4],
    [2, 1.35],
    [2.25, 1.34],
    [2.5, 1.33],
    [2.75, 1.29],
    [3, 1.25],
    [3.5, 1.11],
    [4, 1.19],
    [5, 1.15],
    [6, 1.12],
    [8, 1.08],
  ]);

  private readonly whisperShearFactorValues: Map<number, number> = new Map<number, number>([
    [1, 3.5],
    [1.25, 3.08],
    [1.5, 2.66],
    [1.75, 2.46],
    [2, 2.25],
    [2.25, 2.13],
    [2.5, 2],
    [2.75, 1.92],
    [3, 1.83],
    [3.5, 1.71],
    [4, 1.62],
    [5, 1.5],
    [6, 1.41],
    [8, 1.31],
  ]);

  private readonly maxPunchingForces: Map<PunchingMachineType, number> = new Map<
    PunchingMachineType,
    number
  >([
    [PunchingMachineType.TRU_PUNCH_1000_S05, 165],
    [PunchingMachineType.TRU_PUNCH_1000_S19, 165],
    [PunchingMachineType.TRU_PUNCH_2000_S18, 165],
    [PunchingMachineType.TRU_PUNCH_3000_S11, 180],
    [PunchingMachineType.TRU_PUNCH_3000_S20, 180],
    [PunchingMachineType.TRU_PUNCH_5000_S01, 220],
    [PunchingMachineType.TRU_PUNCH_5000_S10, 220],
    [PunchingMachineType.TRU_PUNCH_5000_S12, 220],
    [PunchingMachineType.TRU_MATIC_1000_K07, 165],
    [PunchingMachineType.TRU_MATIC_3000_K04, 165],
    [PunchingMachineType.TRU_MATIC_3000_K09, 165],
    [PunchingMachineType.TRU_MATIC_6000_K01, 220],
    [PunchingMachineType.TRU_MATIC_6000_K05, 180],
    [PunchingMachineType.TRU_MATIC_6000_K06, 180],
    [PunchingMachineType.TRU_MATIC_7000_K02, 220],
    [PunchingMachineType.TRU_MATIC_7000_K08, 220],
  ]);

  private calculationResult: BehaviorSubject<PunchingForceCalculationResult | undefined> =
    new BehaviorSubject<PunchingForceCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  constructor(
    private dimensionsValidator: DimensionsValidatorService,
    public translations: TranslationHelper,
    private translateService: TranslateService,
  ) {}

  public calculate(
    machineType: PunchingMachineType,
    material: Material,
    sheetThicknesses: number,
    dimensions: Dimensions,
  ): void {
    const dimensionsError = this.dimensionsValidator.validate(dimensions);
    if (dimensionsError) {
      this.calculationResult.next({
        label: 'undefined',
        error: dimensionsError,
      });
    } else {
      const flatPunchingForce = this.calculatePunchingForce(
        machineType,
        material,
        sheetThicknesses,
        dimensions,
        ShearFactor.FLAT,
      );

      const whisperPunchingForce = this.calculatePunchingForce(
        machineType,
        material,
        sheetThicknesses,
        dimensions,
        ShearFactor.WHISPER,
      );

      const roofPunchingForce = this.calculatePunchingForce(
        machineType,
        material,
        sheetThicknesses,
        dimensions,
        ShearFactor.ROOF,
      );

      let errorMessage: string | undefined;
      let flatPunchingForceErrorMessage: string | undefined;
      let whisperPunchingForceErrorMessage: string | undefined;
      let roofPunchingForceErrorMessage: string | undefined;
      let flatPunchErrorMessage: string | undefined;
      let whisperPunchErrorMessage: string | undefined;
      let roofPunchErrorMessage: string | undefined;
      let flatDieErrorMessage: string | undefined;
      let whisperDieErrorMessage: string | undefined;
      let roofDieErrorMessage: string | undefined;

      const outerCircleDiameter = calculateOuterCircleDiameter(dimensions);

      const flatRecommendedPunch = this.calculateRecommendedPunch(
        outerCircleDiameter,
        flatPunchingForce,
      );
      const flatRecommendedDie = this.calculateRecommendedDie(
        outerCircleDiameter,
        flatPunchingForce,
      );

      if (flatRecommendedPunch === PunchSize.NOT_POSSIBLE) {
        flatPunchErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCHING_FORCE_TOO_HIGH,
        );
      }

      if (flatRecommendedDie === DieSize.NOT_POSSIBLE) {
        flatDieErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCHING_FORCE_TOO_HIGH,
        );
      }

      const whisperRecommendedPunch = this.calculateRecommendedPunch(
        outerCircleDiameter,
        whisperPunchingForce,
      );
      const whisperRecommendedDie = this.calculateRecommendedDie(
        outerCircleDiameter,
        whisperPunchingForce,
      );

      if (
        whisperRecommendedPunch === PunchSize.SIZE_0_10POINT5MM ||
        whisperRecommendedPunch === PunchSize.SIZE_0_6MM
      ) {
        whisperPunchErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCH_NOT_AVAILABLE,
        );
      }

      if (whisperRecommendedPunch === PunchSize.NOT_POSSIBLE) {
        whisperPunchErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCHING_FORCE_TOO_HIGH,
        );
      }

      if (whisperRecommendedDie === DieSize.NOT_POSSIBLE) {
        whisperDieErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCHING_FORCE_TOO_HIGH,
        );
      }

      const roofRecommendedPunch = this.calculateRecommendedPunch(
        outerCircleDiameter,
        roofPunchingForce,
      );
      const roofRecommendedDie = this.calculateRecommendedDie(
        outerCircleDiameter,
        roofPunchingForce,
      );

      if (
        roofRecommendedPunch === PunchSize.SIZE_0_10POINT5MM ||
        roofRecommendedPunch === PunchSize.SIZE_0_6MM
      ) {
        roofPunchErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCH_NOT_AVAILABLE,
        );
      }

      if (roofRecommendedPunch === PunchSize.NOT_POSSIBLE) {
        roofPunchErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCHING_FORCE_TOO_HIGH,
        );
      }

      if (roofRecommendedDie === DieSize.NOT_POSSIBLE) {
        roofDieErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.PUNCHING_FORCE_TOO_HIGH,
        );
      }

      const maxPunchingForceOfMachine = this.maxPunchingForces.get(machineType) ?? 0;
      if (flatPunchingForce > maxPunchingForceOfMachine) {
        flatPunchingForceErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.MACHINE_LIMIT_EXCEEDED,
        );
      }
      if (whisperPunchingForce > maxPunchingForceOfMachine) {
        whisperPunchingForceErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.MACHINE_LIMIT_EXCEEDED,
        );
      }
      if (roofPunchingForce > maxPunchingForceOfMachine) {
        roofPunchingForceErrorMessage = this.translateService.instant(
          this.translations.CALCULATIONS.ERROR_MESSAGES.MACHINE_LIMIT_EXCEEDED,
        );
      }

      this.calculationResult.next({
        label: 'CalculationDone!',
        error: errorMessage,
        outerCircleDiameter: outerCircleDiameter,
        flatPunchingForce: flatPunchingForce,
        whisperPunchingForce: whisperPunchingForce,
        roofPunchingForce: roofPunchingForce,
        flatRecommendedPunch: flatRecommendedPunch,
        flatRecommendedDie: flatRecommendedDie,
        whisperRecommendedPunch: whisperRecommendedPunch,
        whisperRecommendedDie: whisperRecommendedDie,
        roofRecommendedPunch: roofRecommendedPunch,
        roofRecommendedDie: roofRecommendedDie,
        flatPunchingForceErrorMessage: flatPunchingForceErrorMessage,
        whisperPunchingForceErrorMessage: whisperPunchingForceErrorMessage,
        roofPunchingForceErrorMessage: roofPunchingForceErrorMessage,
        flatPunchErrorMessage: flatPunchErrorMessage,
        whisperPunchErrorMessage: whisperPunchErrorMessage,
        roofPunchErrorMessage: roofPunchErrorMessage,
        flatDieErrorMessage: flatDieErrorMessage,
        whisperDieErrorMessage: whisperDieErrorMessage,
        roofDieErrorMessage: roofDieErrorMessage,
      });
    }
  }

  public clearResult(): void {
    this.calculationResult.next(undefined);
  }

  private calculatePunchingForce(
    machineType: PunchingMachineType,
    material: Material,
    sheetThickness: number,
    dimensions: Dimensions,
    shearFactor: ShearFactor,
  ): number {
    const tensileStrength = this.getTensileStrengthForMaterial(material);
    const pi = 3.1415926535;
    const shearFactorValue = this.getShearFactorValue(sheetThickness, shearFactor);

    let punchingForce = 0;

    switch (dimensions.geometry) {
      case Geometry.ROUND:
        punchingForce =
          (pi * (dimensions.d ?? 1) * sheetThickness * tensileStrength) / shearFactorValue;
        break;
      case Geometry.SQUARE:
        punchingForce =
          (4 * (dimensions.a ?? 1) * sheetThickness * tensileStrength) / shearFactorValue;
        break;
      case Geometry.OBLONG:
      case Geometry.RECTANGLE:
        punchingForce =
          (2 * ((+dimensions.a ?? 1) + (+dimensions.b ?? 1)) * sheetThickness * tensileStrength) /
          shearFactorValue;
        break;
    }
    // divide by 1000 to convert from N to kN
    return punchingForce / 1000;
  }

  calculateRecommendedPunch(outerCircleDiameter: number, punchingForce: number): PunchSize {
    let punchSize: PunchSize = PunchSize.NOT_POSSIBLE;
    if (punchingForce <= 50) {
      if (outerCircleDiameter >= 1 && outerCircleDiameter <= 6) {
        punchSize = PunchSize.SIZE_0_6MM;
      } else if (outerCircleDiameter > 6 && outerCircleDiameter <= 10.5) {
        punchSize = PunchSize.SIZE_0_10POINT5MM;
      } else if (outerCircleDiameter > 10.5 && outerCircleDiameter <= 30) {
        punchSize = PunchSize.SIZE_1;
      } else if (outerCircleDiameter > 30 && outerCircleDiameter <= 76.2) {
        punchSize = PunchSize.SIZE_2;
      }
    } else if (punchingForce > 50 && punchingForce <= 200) {
      if (outerCircleDiameter >= 1 && outerCircleDiameter <= 30) {
        punchSize = PunchSize.SIZE_1;
      } else if (outerCircleDiameter > 30 && outerCircleDiameter <= 76.2) {
        punchSize = PunchSize.SIZE_2;
      }
    } else if (punchingForce > 200) {
      if (outerCircleDiameter >= 6 && outerCircleDiameter <= 42) {
        punchSize = PunchSize.SIZE_2_ENFORCED_SHOULDER;
      }
    }

    return punchSize;
  }

  calculateRecommendedDie(outerCircleDiameter: number, punchingForce: number): DieSize {
    let dieSize: DieSize = DieSize.NOT_POSSIBLE;
    if (punchingForce < 180) {
      if (outerCircleDiameter >= 1 && outerCircleDiameter <= 32) {
        dieSize = DieSize.SIZE_1;
      } else if (outerCircleDiameter > 32 && outerCircleDiameter <= 78.4) {
        dieSize = DieSize.SIZE_2;
      }
    } else if (punchingForce >= 180 && punchingForce <= 250) {
      if (outerCircleDiameter >= 1 && outerCircleDiameter <= 32) {
        dieSize = DieSize.SIZE_1;
      } else if (outerCircleDiameter > 32 && outerCircleDiameter <= 62) {
        dieSize = DieSize.SIZE_2_ENFORCED;
      }
    }

    return dieSize;
  }

  getShearFactorValue(sheetThickness: number, shearFactor: ShearFactor): number {
    // If the shear factor is FLAT the value will always be 1. Therefore this is the default case.
    let shearFactorValue = 1;

    if (shearFactor === ShearFactor.ROOF) {
      shearFactorValue = this.roofShearFactorValues.get(sheetThickness) || 1;
    }

    if (shearFactor === ShearFactor.WHISPER) {
      shearFactorValue = this.whisperShearFactorValues.get(sheetThickness) || 1;
    }

    return shearFactorValue;
  }

  getTensileStrengthForMaterial(material: Material): number {
    switch (material) {
      case Material.ALUMINUM:
        return 300;
      case Material.STAINLESS_STEEL:
        return 700;
      case Material.STEEL:
        return 400;
    }
  }
}
