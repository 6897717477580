<div class="headline">{{ translations.ABOUT.SETTINGS | translate }}</div>

<div class="setting">
  <div class="label">{{ translations.SETTINGS.LANGUAGE | translate }}</div>
  <xui-dropdown #langDropdown class="language-drop-down" [label]="selectedLanguage | translate">
    <xui-dropdown-item
      *ngFor="let language of availableLanguages"
      (click)="changeLanguage(language); langDropdown.close()"
      class="language-item"
    >
      {{ language | language }}
    </xui-dropdown-item>
  </xui-dropdown>
</div>

<div class="setting">
  <div class="label">{{ translations.SETTINGS.UNIT_SYSTEM | translate }}</div>
  <xui-dropdown
    #unitDropdown
    class="unit-drop-down"
    [label]="'ENUMS.SYSTEM_OF_UNITS.' + selectedUnit | uppercase | translate"
  >
    <xui-dropdown-item
      *ngFor="let unit of availableUnits"
      (click)="changeUnit(unit); unitDropdown.close()"
      class="unit-item"
    >
      {{ 'ENUMS.SYSTEM_OF_UNITS.' + unit | uppercase | translate }}
    </xui-dropdown-item>
  </xui-dropdown>
</div>

<div class="setting">
  <div class="label">{{ translations.SETTINGS.CURRENCY | translate }}</div>
  <xui-dropdown
    #currencyDropdown
    class="unit-drop-down"
    [label]="'ENUMS.CURRENCY.' + selectedCurrency | uppercase | translate"
  >
    <xui-dropdown-item
      *ngFor="let currency of availableCurrencies"
      (click)="changeCurrency(currency); currencyDropdown.close()"
      class="unit-item"
    >
      {{ 'ENUMS.CURRENCY.' + currency | uppercase | translate }}
    </xui-dropdown-item>
  </xui-dropdown>
</div>

<a class="output-content link" data-ux-cookie-layer="settings" href="#">
  {{ translations.XGUIDE.PRIVACY.TITLE | translate }}
</a>
