<div class="input-container">
  <div class="stacked">
    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}
    </span>
    <xui-dropdown #sheetThicknessDropDown class="drop-down" [label]="selectedSheetThicknessInCmOrInch.value"
                  data-ngtx="cutting-clearance:sheet-thickness">

      <xui-value #selectedSheetThicknessInCmOrInch  [binding]="selectedSheetThickness">
        <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
        <xui-number-formatter [maxDecimals]="2" [unit]="selectedSheetThicknessInCmOrInch.unit"></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        *ngFor="let sheetThickness of sheetThicknesses"
        (click)="selectedSheetThickness = sheetThickness; clearResult(); sheetThicknessDropDown.close()"
      >
        <xui-value #sheetThicknessInCentimeterOrInch [binding]="sheetThickness">
          <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
          <xui-number-formatter [maxDecimals]="2" [unit]="sheetThicknessInCentimeterOrInch.unit"></xui-number-formatter>
        </xui-value>
        {{ sheetThicknessInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
    </span>
    <xui-dropdown #materialDropDown class="drop-down" [label]="selectedMaterialName()"
                  data-ngtx="cutting-clearance:material">
      <xui-dropdown-item
        *ngFor="let material of materials"
        (click)="selectedMaterial = material.id; clearResult(); materialDropDown.close()"
      >
        {{ material?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.DIMENSIONS | translate }}
    </span>

    <lsb-dimensions [(dimensions)]="dimensions" [geometries]="availableGeometries" (change)="clearResult()"></lsb-dimensions>

    <span class="calculationError" *ngIf="calculationError$ | async as error">
      {{error}}
    </span>

    <xui-button
      class="calculate-button"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_CUTTING_CLEARANCE }}"
      (click)="calculate()"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>
  <div class="results-container" >
    <div class="stacked" *ngIf="calculationResult$ | async as calculationResult">
      <span class="calculationResultTitle" *ngIf="!calculationResult.error">
        {{ translations.CALCULATIONS.RESULT_LABELS.CUTTING_CLEARANCE | translate }}
      </span>
      <ng-container *ngIf="!calculationResult.error && calculationResult.a">
        <xui-caption-label
          [caption]="'a'"
          [label]="aValue.value"
          class="dimensionResultArea"
        >
          <xui-value #aValue [binding]="calculationResult.a">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="aValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="!calculationResult.error && calculationResult.b">
        <xui-caption-label
          [caption]="'b'"
          [label]="bValue.value"
          class="dimensionResultArea"
        >
          <xui-value #bValue [binding]="calculationResult.b">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="bValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="!calculationResult.error && calculationResult.d">
        <xui-caption-label
          [caption]="'d'"
          [label]="dValue.value"
          class="dimensionResultArea"
        >
          <xui-value #dValue [binding]="calculationResult.d">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="dValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="!calculationResult.error && calculationResult.l">
        <xui-caption-label
          [caption]="'l'"
          [label]="lValue.value"
          class="dimensionResultArea"
        >
          <xui-value #lValue [binding]="calculationResult.l">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="lValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

    </div>
  </div>
</div>
