import { PrePunchingDiameterType } from '../model/pre-punching-diameter-type';
import { ApplicationType } from '../model/application-type';
import { Material } from '../model/material';

/**
 * There is no formula for pre punching diameter, only the table of all possible values for different combinations
 */
export const PRE_PUNCHING_DIAMETERS: PrePunchingDiameterType[] = [
  // Countersink under - A 2.5
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 2.5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.8],
      [Material.STAINLESS_STEEL, 4.8],
      [Material.ALUMINUM, 4.8],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 2.5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.1],
      [Material.STAINLESS_STEEL, 4.1],
      [Material.ALUMINUM, 4.1],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 2.5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.0],
      [Material.STAINLESS_STEEL, 4.0],
      [Material.ALUMINUM, 4.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 2.5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.9],
      [Material.STAINLESS_STEEL, 4.2],
      [Material.ALUMINUM, 3.9],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 2.5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.9],
      [Material.STAINLESS_STEEL, 4.2],
      [Material.ALUMINUM, 3.9],
    ]),
  },
  // Countersink under - A 3
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 3',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.7],
      [Material.STAINLESS_STEEL, 5.5],
      [Material.ALUMINUM, 5.7],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 3',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.9],
      [Material.STAINLESS_STEEL, 4.9],
      [Material.ALUMINUM, 4.9],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 3',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.7],
      [Material.STAINLESS_STEEL, 4.7],
      [Material.ALUMINUM, 4.7],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 3',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.3],
      [Material.STAINLESS_STEEL, 4.3],
      [Material.ALUMINUM, 4.3],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 3',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.3],
      [Material.STAINLESS_STEEL, 4.6],
      [Material.ALUMINUM, 4.3],
    ]),
  },
  // Countersink under - A 4
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 4',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.9],
      [Material.STAINLESS_STEEL, 6.9],
      [Material.ALUMINUM, 6.9],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 4',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.4],
      [Material.STAINLESS_STEEL, 6.9],
      [Material.ALUMINUM, 6.4],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 4',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.7],
      [Material.STAINLESS_STEEL, 6.0],
      [Material.ALUMINUM, 5.7],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 4',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.7],
      [Material.STAINLESS_STEEL, 6.0],
      [Material.ALUMINUM, 5.7],
    ]),
  },
  // Countersink under - A 5
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.8],
      [Material.STAINLESS_STEEL, 8.8],
      [Material.ALUMINUM, 8.8],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.3],
      [Material.STAINLESS_STEEL, 8.8],
      [Material.ALUMINUM, 8.3],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.6],
      [Material.STAINLESS_STEEL, 8.0],
      [Material.ALUMINUM, 7.6],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, 7.2],
      [Material.ALUMINUM, 7.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 5',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.0],
    ]),
  },
  // Countersink under - A 6
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 6',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 10.3],
      [Material.STAINLESS_STEEL, 11.0],
      [Material.ALUMINUM, 10.3],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 6',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.7],
      [Material.STAINLESS_STEEL, 10.1],
      [Material.ALUMINUM, 9.7],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 6',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.0],
      [Material.STAINLESS_STEEL, 9.4],
      [Material.ALUMINUM, 9.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 6',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.5],
      [Material.STAINLESS_STEEL, 9.4],
      [Material.ALUMINUM, 8.5],
    ]),
  },
  // Countersink under - A 8
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 8',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 14.2],
      [Material.STAINLESS_STEEL, 14.8],
      [Material.ALUMINUM, 14.2],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 8',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.5],
      [Material.STAINLESS_STEEL, 14.0],
      [Material.ALUMINUM, 13.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 8',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.6],
      [Material.STAINLESS_STEEL, 14.0],
      [Material.ALUMINUM, 13.6],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'A 8',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 12.5],
      [Material.STAINLESS_STEEL, 13.2],
      [Material.ALUMINUM, 12.5],
    ]),
  },
  // Countersink under - F 3
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 3',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.0],
      [Material.STAINLESS_STEEL, 6.0],
      [Material.ALUMINUM, 6.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 3',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.2],
      [Material.STAINLESS_STEEL, 5.2],
      [Material.ALUMINUM, 5.2],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 3',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.2],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 3',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.7],
      [Material.ALUMINUM, 4.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 3',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.7],
      [Material.ALUMINUM, 4.5],
    ]),
  },
  // Countersink under - F 4
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 4',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.7],
      [Material.STAINLESS_STEEL, 7.7],
      [Material.ALUMINUM, 7.7],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 4',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.9],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 6.9],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 4',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.5],
      [Material.STAINLESS_STEEL, 6.7],
      [Material.ALUMINUM, 6.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 4',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.0],
      [Material.STAINLESS_STEEL, 6.3],
      [Material.ALUMINUM, 6.0],
    ]),
  },
  // Countersink under - F 5
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 10.0],
      [Material.STAINLESS_STEEL, 10.0],
      [Material.ALUMINUM, 10.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.2],
      [Material.STAINLESS_STEEL, 10.0],
      [Material.ALUMINUM, 9.2],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.6],
      [Material.STAINLESS_STEEL, 9.0],
      [Material.ALUMINUM, 8.6],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.0],
      [Material.STAINLESS_STEEL, 8.4],
      [Material.ALUMINUM, 8.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 5',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.8],
      [Material.STAINLESS_STEEL, 8.4],
      [Material.ALUMINUM, 8.0],
    ]),
  },
  // Countersink under - F 6
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 6',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 11.4],
      [Material.STAINLESS_STEEL, 11.6],
      [Material.ALUMINUM, 11.4],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 6',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 10.8],
      [Material.STAINLESS_STEEL, 11.2],
      [Material.ALUMINUM, 10.8],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 6',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 10.4],
      [Material.STAINLESS_STEEL, 11.0],
      [Material.ALUMINUM, 10.4],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 6',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.1],
      [Material.STAINLESS_STEEL, 10.0],
      [Material.ALUMINUM, 9.1],
    ]),
  },
  // Countersink under - F 8
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 8',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 16.0],
      [Material.STAINLESS_STEEL, 16.0],
      [Material.ALUMINUM, 16.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 8',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 15.3],
      [Material.STAINLESS_STEEL, 15.7],
      [Material.ALUMINUM, 15.3],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 8',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 15.0],
      [Material.STAINLESS_STEEL, 15.6],
      [Material.ALUMINUM, 15.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'F 8',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.8],
      [Material.STAINLESS_STEEL, 15.6],
      [Material.ALUMINUM, 13.8],
    ]),
  },
  // Countersink under - AM/BM 2.5
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 2.5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.5],
      [Material.ALUMINUM, 4.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 2.5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.3],
      [Material.STAINLESS_STEEL, 4.3],
      [Material.ALUMINUM, 4.3],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 2.5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.0],
      [Material.STAINLESS_STEEL, 4.0],
      [Material.ALUMINUM, 4.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 2.5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.0],
      [Material.STAINLESS_STEEL, 4.0],
      [Material.ALUMINUM, 4.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 2.5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.0],
      [Material.STAINLESS_STEEL, 4.0],
      [Material.ALUMINUM, 4.0],
    ]),
  },
  // Countersink under - AM/BM 3
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 3',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.5],
      [Material.STAINLESS_STEEL, 5.5],
      [Material.ALUMINUM, 5.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 3',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.8],
      [Material.STAINLESS_STEEL, 4.8],
      [Material.ALUMINUM, 4.8],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 3',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.5],
      [Material.ALUMINUM, 4.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 3',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.5],
      [Material.ALUMINUM, 4.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM/BM 3',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.5],
      [Material.ALUMINUM, 4.5],
    ]),
  },
  // Countersink under - AM4
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 4',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.8],
      [Material.STAINLESS_STEEL, 6.8],
      [Material.ALUMINUM, 6.8],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 4',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.0],
      [Material.STAINLESS_STEEL, 6.0],
      [Material.ALUMINUM, 6.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 4',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.5],
      [Material.STAINLESS_STEEL, 5.5],
      [Material.ALUMINUM, 5.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 4',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.5],
      [Material.STAINLESS_STEEL, 5.5],
      [Material.ALUMINUM, 5.5],
    ]),
  },
  // Countersink under - AM 5
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.5],
      [Material.STAINLESS_STEEL, 8.5],
      [Material.ALUMINUM, 8.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.8],
      [Material.STAINLESS_STEEL, 7.8],
      [Material.ALUMINUM, 7.8],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, 7.0],
      [Material.ALUMINUM, 7.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 5',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, 7.0],
      [Material.ALUMINUM, 7.0],
    ]),
  },
  // Countersink under - AM 6
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 6',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.0],
      [Material.STAINLESS_STEEL, 9.0],
      [Material.ALUMINUM, 9.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 6',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.0],
      [Material.STAINLESS_STEEL, 9.0],
      [Material.ALUMINUM, 9.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 6',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.5],
      [Material.STAINLESS_STEEL, 8.5],
      [Material.ALUMINUM, 8.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 6',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.5],
      [Material.STAINLESS_STEEL, 8.5],
      [Material.ALUMINUM, 8.5],
    ]),
  },
  // Countersink under - AM 8
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 8',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.0],
      [Material.STAINLESS_STEEL, 13.0],
      [Material.ALUMINUM, 13.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 8',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.0],
      [Material.STAINLESS_STEEL, 13.0],
      [Material.ALUMINUM, 13.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 8',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.5],
      [Material.STAINLESS_STEEL, 13.5],
      [Material.ALUMINUM, 13.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'AM 8',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 14.0],
      [Material.STAINLESS_STEEL, 14.0],
      [Material.ALUMINUM, 14.0],
    ]),
  },
  // Countersink under - bM4
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 4',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.5],
      [Material.STAINLESS_STEEL, 6.5],
      [Material.ALUMINUM, 6.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 4',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.4],
      [Material.STAINLESS_STEEL, 6.4],
      [Material.ALUMINUM, 6.4],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 4',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.2],
      [Material.STAINLESS_STEEL, 6.2],
      [Material.ALUMINUM, 6.2],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 4',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 6.0],
      [Material.STAINLESS_STEEL, 6.0],
      [Material.ALUMINUM, 6.0],
    ]),
  },
  // Countersink under - BM 5
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.2],
      [Material.STAINLESS_STEEL, 9.2],
      [Material.ALUMINUM, 9.2],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.5],
      [Material.STAINLESS_STEEL, 8.5],
      [Material.ALUMINUM, 8.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.3],
      [Material.STAINLESS_STEEL, 8.3],
      [Material.ALUMINUM, 8.3],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.0],
      [Material.STAINLESS_STEEL, 8.0],
      [Material.ALUMINUM, 8.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 5',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.0],
      [Material.STAINLESS_STEEL, 8.0],
      [Material.ALUMINUM, 8.0],
    ]),
  },
  // Countersink under - BM 6
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 6',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 10.0],
      [Material.STAINLESS_STEEL, 10.0],
      [Material.ALUMINUM, 10.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 6',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.0],
      [Material.STAINLESS_STEEL, 9.0],
      [Material.ALUMINUM, 9.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 6',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.5],
      [Material.STAINLESS_STEEL, 8.5],
      [Material.ALUMINUM, 8.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 6',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.5],
      [Material.STAINLESS_STEEL, 8.5],
      [Material.ALUMINUM, 8.5],
    ]),
  },
  // Countersink under - BM 8
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 8',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.5],
      [Material.STAINLESS_STEEL, 13.5],
      [Material.ALUMINUM, 13.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 8',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 13.5],
      [Material.STAINLESS_STEEL, 13.5],
      [Material.ALUMINUM, 13.5],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 8',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 14.0],
      [Material.STAINLESS_STEEL, 14.0],
      [Material.ALUMINUM, 14.0],
    ]),
  },
  {
    application: ApplicationType.COUNTERSINK_UNDER,
    threadSize: 'BM 8',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 14.5],
      [Material.STAINLESS_STEEL, 14.5],
      [Material.ALUMINUM, 14.5],
    ]),
  },
  // Extrusion upwards for tapping - M2
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M2',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.1],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 1.1],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M2',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.1],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 1.1],
    ]),
  },
  // Extrusion upwards for tapping - M2.5
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M2.5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.0],
      [Material.STAINLESS_STEEL, 1.0],
      [Material.ALUMINUM, 1.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M2.5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.5],
      [Material.STAINLESS_STEEL, 1.5],
      [Material.ALUMINUM, 1.5],
    ]),
  },
  // Extrusion upwards for tapping - M3
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M3',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.5],
      [Material.STAINLESS_STEEL, 1.5],
      [Material.ALUMINUM, 1.8],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M3',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.5],
      [Material.STAINLESS_STEEL, 1.5],
      [Material.ALUMINUM, 1.8],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M3',
    thickness: 2,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.5],
      [Material.STAINLESS_STEEL, 1.8],
      [Material.ALUMINUM, 1.8],
    ]),
  },
  // Extrusion upwards for tapping - M3.5
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M3.5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, 2.0],
      [Material.ALUMINUM, 2.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M3.5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.5],
      [Material.STAINLESS_STEEL, 1.8],
      [Material.ALUMINUM, 2.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M3.5',
    thickness: 2,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.0],
    ]),
  },
  // Extrusion upwards for tapping - M4
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M4',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, 2.0],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M4',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.5],
      [Material.STAINLESS_STEEL, 1.5],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M4',
    thickness: 2,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, 2.0],
      [Material.ALUMINUM, 2.5],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M4',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.5],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.5],
    ]),
  },
  // Extrusion upwards for tapping - M5
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.5],
      [Material.STAINLESS_STEEL, 2.5],
      [Material.ALUMINUM, 2.7],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.5],
      [Material.STAINLESS_STEEL, 2.5],
      [Material.ALUMINUM, 2.7],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M5',
    thickness: 2,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, 2.5],
      [Material.ALUMINUM, 3.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.5],
      [Material.STAINLESS_STEEL, 2.5],
      [Material.ALUMINUM, 3.5],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.5],
      [Material.STAINLESS_STEEL, 3.0],
      [Material.ALUMINUM, 3.5],
    ]),
  },
  // Extrusion upwards for tapping - M6
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M6',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.0],
      [Material.STAINLESS_STEEL, 3.0],
      [Material.ALUMINUM, 3.3],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M6',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.5],
      [Material.STAINLESS_STEEL, 3.5],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M6',
    thickness: 2,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.0],
      [Material.STAINLESS_STEEL, 3.0],
      [Material.ALUMINUM, 3.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M6',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.5],
      [Material.STAINLESS_STEEL, 3.5],
      [Material.ALUMINUM, 4.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M6',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.5],
      [Material.STAINLESS_STEEL, 3.5],
      [Material.ALUMINUM, 4.0],
    ]),
  },
  // Extrusion upwards for tapping - M8
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M8',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.5],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M8',
    thickness: 2,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.5],
      [Material.STAINLESS_STEEL, 4.5],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M8',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.5],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M8',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, undefined],
    ]),
  },
  // Extrusion upwards for tapping - M10
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M10',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, 7.0],
      [Material.ALUMINUM, 7.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M10',
    thickness: 2,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, 7.0],
      [Material.ALUMINUM, 7.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M10',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 7.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: 'M10',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.0],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, undefined],
    ]),
  },
  // Extrusion upwards for tapping - 3-48
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '3-48',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.0],
      [Material.STAINLESS_STEEL, 1.0],
      [Material.ALUMINUM, 1.0],
    ]),
  },
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '3-48',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.5],
      [Material.STAINLESS_STEEL, 1.5],
      [Material.ALUMINUM, 1.5],
    ]),
  },
  // Extrusion upwards for tapping - 4-40
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '4-40',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.58],
      [Material.STAINLESS_STEEL, 1.58],
      [Material.ALUMINUM, 1.58],
    ]),
  },
  // Extrusion upwards for tapping - 5-40
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '5-40',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.82],
      [Material.STAINLESS_STEEL, 1.82],
      [Material.ALUMINUM, 1.82],
    ]),
  },
  // Extrusion upwards for tapping - 6-32
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '6-32',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.93],
      [Material.STAINLESS_STEEL, 1.93],
      [Material.ALUMINUM, 1.93],
    ]),
  },
  // Extrusion upwards for tapping - 8-32
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '8-32',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.38],
      [Material.STAINLESS_STEEL, 2.38],
      [Material.ALUMINUM, 2.38],
    ]),
  },
  // Extrusion upwards for tapping - 10-24
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '10-24',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.54],
      [Material.STAINLESS_STEEL, 2.54],
      [Material.ALUMINUM, 2.54],
    ]),
  },
  // Extrusion upwards for tapping - 12-24
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '12-24',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.94],
      [Material.STAINLESS_STEEL, 2.94],
      [Material.ALUMINUM, 2.94],
    ]),
  },
  // Extrusion upwards for tapping - 1/4-20
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '1/4-20',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.32],
      [Material.STAINLESS_STEEL, 3.32],
      [Material.ALUMINUM, 3.32],
    ]),
  },
  // Extrusion upwards for tapping - 5/16-18
  {
    application: ApplicationType.EXTRUSION,
    threadSize: '5/16-18',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.19],
      [Material.STAINLESS_STEEL, 4.19],
      [Material.ALUMINUM, 4.19],
    ]),
  },
  // Tapping in even sheet - M2
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.85],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 1.85],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.85],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 1.85],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.85],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 1.85],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 1.85],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 1.85],
    ]),
  },
  // Tapping in even sheet - M2.5
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2.5',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2.5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2.5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2.5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2.5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M2.5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  // Tapping in even sheet - M3
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.8],
      [Material.STAINLESS_STEEL, 2.8],
      [Material.ALUMINUM, 2.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.8],
      [Material.STAINLESS_STEEL, 2.8],
      [Material.ALUMINUM, 2.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.8],
      [Material.STAINLESS_STEEL, 2.8],
      [Material.ALUMINUM, 2.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.8],
      [Material.STAINLESS_STEEL, 2.8],
      [Material.ALUMINUM, 2.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.8],
      [Material.STAINLESS_STEEL, 2.8],
      [Material.ALUMINUM, 2.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.8],
      [Material.STAINLESS_STEEL, 2.8],
      [Material.ALUMINUM, 2.8],
    ]),
  },
  // Tapping in even sheet - M3.5
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3.5',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.25],
      [Material.STAINLESS_STEEL, 3.25],
      [Material.ALUMINUM, 3.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3.5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.25],
      [Material.STAINLESS_STEEL, 3.25],
      [Material.ALUMINUM, 3.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3.5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.25],
      [Material.STAINLESS_STEEL, 3.25],
      [Material.ALUMINUM, 3.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3.5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.25],
      [Material.STAINLESS_STEEL, 3.25],
      [Material.ALUMINUM, 3.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3.5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.25],
      [Material.STAINLESS_STEEL, 3.25],
      [Material.ALUMINUM, 3.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M3.5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.25],
      [Material.STAINLESS_STEEL, 3.25],
      [Material.ALUMINUM, 3.25],
    ]),
  },
  // Tapping in even sheet - M4
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M4',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.7],
      [Material.STAINLESS_STEEL, 3.7],
      [Material.ALUMINUM, 3.7],
    ]),
  },
  // Tapping in even sheet - M5
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M5',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.65],
      [Material.STAINLESS_STEEL, 4.65],
      [Material.ALUMINUM, 4.65],
    ]),
  },
  // Tapping in even sheet - M6
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 5.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M6',
    thickness: 6.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.55],
      [Material.STAINLESS_STEEL, 5.55],
      [Material.ALUMINUM, 5.55],
    ]),
  },
  // Tapping in even sheet - M8
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 5.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 6.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 6.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 7.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 7.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M8',
    thickness: 8.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.4],
      [Material.STAINLESS_STEEL, 7.4],
      [Material.ALUMINUM, 7.4],
    ]),
  },
  // Tapping in even sheet - M10
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 5.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 6.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, 9.3],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 6.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 7.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 7.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: 'M10',
    thickness: 8.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 9.3],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 9.3],
    ]),
  },
  // Tapping in even sheet - 2-56
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '2-56',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 2.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '2-56',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 2.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '2-56',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 2.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '2-56',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.0],
      [Material.STAINLESS_STEEL, undefined],
      [Material.ALUMINUM, 2.0],
    ]),
  },
  // Tapping in even sheet - 3-48
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3-48',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3-48',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3-48',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3-48',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3-48',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3-48',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.3],
      [Material.STAINLESS_STEEL, 2.3],
      [Material.ALUMINUM, 2.3],
    ]),
  },
  // Tapping in even sheet - 4-40
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '4-40',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.55],
      [Material.STAINLESS_STEEL, 2.55],
      [Material.ALUMINUM, 2.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '4-40',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.55],
      [Material.STAINLESS_STEEL, 2.55],
      [Material.ALUMINUM, 2.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '4-40',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.55],
      [Material.STAINLESS_STEEL, 2.55],
      [Material.ALUMINUM, 2.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '4-40',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.55],
      [Material.STAINLESS_STEEL, 2.55],
      [Material.ALUMINUM, 2.55],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '4-40',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.55],
      [Material.STAINLESS_STEEL, 2.55],
      [Material.ALUMINUM, 2.55],
    ]),
  },
  // Tapping in even sheet - 5-40
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5-40',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.9],
      [Material.STAINLESS_STEEL, 2.9],
      [Material.ALUMINUM, 2.9],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5-40',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.9],
      [Material.STAINLESS_STEEL, 2.9],
      [Material.ALUMINUM, 2.9],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5-40',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.9],
      [Material.STAINLESS_STEEL, 2.9],
      [Material.ALUMINUM, 2.9],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5-40',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.9],
      [Material.STAINLESS_STEEL, 2.9],
      [Material.ALUMINUM, 2.9],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5-40',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.9],
      [Material.STAINLESS_STEEL, 2.9],
      [Material.ALUMINUM, 2.9],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5-40',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 2.9],
      [Material.STAINLESS_STEEL, 2.9],
      [Material.ALUMINUM, 2.9],
    ]),
  },
  // Tapping in even sheet - 6-32
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '6-32',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.15],
      [Material.STAINLESS_STEEL, 3.15],
      [Material.ALUMINUM, 3.15],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '6-32',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.15],
      [Material.STAINLESS_STEEL, 3.15],
      [Material.ALUMINUM, 3.15],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '6-32',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.15],
      [Material.STAINLESS_STEEL, 3.15],
      [Material.ALUMINUM, 3.15],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '6-32',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.15],
      [Material.STAINLESS_STEEL, 3.15],
      [Material.ALUMINUM, 3.15],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '6-32',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.15],
      [Material.STAINLESS_STEEL, 3.15],
      [Material.ALUMINUM, 3.15],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '6-32',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.15],
      [Material.STAINLESS_STEEL, 3.15],
      [Material.ALUMINUM, 3.15],
    ]),
  },
  // Tapping in even sheet - 8-32
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '8-32',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 3.8],
      [Material.STAINLESS_STEEL, 3.8],
      [Material.ALUMINUM, 3.8],
    ]),
  },
  // Tapping in even sheet - 10-24
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '10-24',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 4.35],
      [Material.STAINLESS_STEEL, 4.35],
      [Material.ALUMINUM, 4.35],
    ]),
  },
  // Tapping in even sheet - 12-24
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '12-24',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.0],
      [Material.STAINLESS_STEEL, 5.0],
      [Material.ALUMINUM, 5.0],
    ]),
  },
  // Tapping in even sheet - 1/4-20
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '1/4-20',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 5.75],
      [Material.STAINLESS_STEEL, 5.75],
      [Material.ALUMINUM, 5.75],
    ]),
  },
  // Tapping in even sheet - 5/16-18
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 5.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 6.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '5/16-18',
    thickness: 6.4,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 7.25],
      [Material.STAINLESS_STEEL, 7.25],
      [Material.ALUMINUM, 7.25],
    ]),
  },
  // Tapping in even sheet - 3/18-16
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 0.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 1.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 1.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 2.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 2.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 3.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 3.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 4.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 4.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 5.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 5.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 6.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 6.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 7.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 7.5,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
  {
    application: ApplicationType.TAPPING_EVEN,
    threadSize: '3/8-16',
    thickness: 8.0,
    diameters: new Map<Material, number | undefined>([
      [Material.STEEL, 8.75],
      [Material.STAINLESS_STEEL, 8.75],
      [Material.ALUMINUM, 8.75],
    ]),
  },
];
