<div class="input-container">
    <div class="stacked">
        
      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.MATERIAL | translate }}
      </span>
      <xui-dropdown #materialDropDown class="drop-down" [label]="selectedMaterialName()"
                    data-ngtx="sheet-thickness:material">
        <xui-dropdown-item
          *ngFor="let material of materials"
          (click)="selectedMaterial = material.id; materialDropDown.close(); clearResult()"
        >
          {{ material?.name }}
        </xui-dropdown-item>
      </xui-dropdown>

      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.GAUGE | translate }}
      </span>
      <xui-dropdown #gaugeDropDown class="drop-down" [label]="selectedGauge"
                    data-ngtx="sheet-thickness:gauge">
        <xui-dropdown-item
          *ngFor="let gauge of gauges"
          (click)="selectedGauge = gauge; gaugeDropDown.close(); clearResult()"
        >
          {{ gauge }}
        </xui-dropdown-item>
      </xui-dropdown>
  
      <span class="calculationError" *ngIf="calculationError$ | async as error">
        {{error}}
      </span>
      <div class="button-area">
        <xui-button
          class="calculate-button"
          xuiClickUsageTracking="{{ uiElementIds.CALCULATE_SHEET_THICKNESS }}"
          (click)="calculate()"
        >
          {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
        </xui-button>
      </div>
  
    </div>
</div>

<div  class="results-container" >
    <div *ngIf="calculationService.calculationResult$ | async as calculationResult">  
      <div *ngIf="!calculationResult.error" >
  
        <span class="calculationResultTitle">{{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate}}</span>
            <xui-caption-label class="calculation-result-label" [caption]="translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate">
            <xui-value
                #sheetThickness
                [(binding)]="calculationResult.sheetThickness"
                [fallbackValue]="0"
            >    
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
                <xui-number-formatter [maxDecimals]="2" [unit]="sheetThickness.unit"></xui-number-formatter>
            </xui-value>
                {{sheetThickness.value}}
            </xui-caption-label>
  
        </div>
    </div>
</div>