<div class="input-container">
    <div class="stacked">
      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.PUNCH_TYPE | translate }}
      </span>
      <xui-dropdown #punchSizeDropDown class="drop-down" [label]="selectedPunchSizeName()"
                    data-ngtx="maximum-edge-length:punch-Size">
        <xui-dropdown-item
          *ngFor="let punchType of punchSizes"
          (click)="selectedPunchSize = punchType?.id; punchSizeDropDown.close(); clearResult()"
        >
          {{ punchType?.name }}
        </xui-dropdown-item>
      </xui-dropdown>

      <span class="dropdown-label">
        {{ translations.CALCULATIONS.INPUT_LABELS.PUNCH_GEOMETRY | translate }}
      </span>  
  
      <lsb-dimensions [(dimensions)]="dimensions" [geometries]="availableGeometries" [reducedDimensions]="true" (change)="clearResult()"></lsb-dimensions>
  
      <span class="calculationError" *ngIf="calculationError$ | async as error">
        {{error}}
      </span>
      <div class="button-area">
        <xui-button
          class="calculate-button"
          xuiClickUsageTracking="{{ uiElementIds.CALCULATE_MAXIMUM_EDGE_LENGTH }}"
          (click)="calculate()"
        >
          {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
        </xui-button>
      </div>
    </div>  
  </div>
  <div  class="results-container" >
    <div *ngIf="calculationService.calculationResult$ | async as calculationResult">  
      <div *ngIf="!calculationResult.error" >
  
      <span class="calculationResultTitle">{{ translations.CALCULATIONS.RESULT_LABELS.CALCULATED_LENGTHS | translate}}</span>
            <div class="labelContainer">
                <xui-caption-label class="calculation-result-label" [caption]="translations.CALCULATIONS.RESULT_LABELS.MAXIMUM_EDGE_LENGTH | translate"  >
                    <xui-value #maximumEdgeLengthInCentimeterOrInch [binding]="calculationResult.maximumEdgeLength">
                        <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
                        <xui-number-formatter [maxDecimals]="2"></xui-number-formatter>
                    </xui-value>
                    {{ maximumEdgeLengthInCentimeterOrInch.value }} {{ maximumEdgeLengthInCentimeterOrInch.unit }}
                </xui-caption-label>
                <xui-caption-label class="calculation-result-label" [caption]="translations.CALCULATIONS.RESULT_LABELS.MAXIMUM_OUTER_CIRCLE_DIAMETER | translate"  >            
                    <xui-value #maximumOuterCircleDiameterInCentimeterOrInch [binding]="calculationResult.maximumOuterCircleDiameter">
                        <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
                        <xui-number-formatter [maxDecimals]="2"></xui-number-formatter>
                    </xui-value>
                    {{ maximumOuterCircleDiameterInCentimeterOrInch.value }} {{ maximumOuterCircleDiameterInCentimeterOrInch.unit }}
                </xui-caption-label>    
            </div>
      </div>  
    </div>
  </div>