import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { PUNCHING_MACHINES } from '../../data/constants';
import { MAX_SHEET_THICKNESSES } from '../../data/maximum-sheet-thicknesses';
import {
  dieSizeToTranslation,
  materialToTranslation,
  punchSizeToTranslation,
} from '../../helpers/enum-to-translation';
import { Dimensions } from '../../model/dimensions';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Geometry } from '../../model/geometry';
import { Material } from '../../model/material';
import { PunchingMachineType } from '../../model/punching-machine';
import { PunchingForceCalculationService } from '../../services/punching-force-calculation.service';

@Component({
  selector: 'lsb-punching-force',
  templateUrl: './punching-force.component.html',
  styleUrls: ['./punching-force.component.scss'],
})
export class PunchingForceComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;
  public machines = PUNCHING_MACHINES;
  public selectedMachine: PunchingMachineType = PUNCHING_MACHINES[0].type;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STEEL;

  private readonly allSheetThicknesses: number[] = [
    1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.5, 4, 5, 6, 8,
  ];
  public availableSheetThicknesses: number[];
  public selectedSheetThickness: number = this.allSheetThicknesses[0];

  public availableGeometries: Geometry[] = [
    Geometry.ROUND,
    Geometry.RECTANGLE,
    Geometry.SQUARE,
    Geometry.OBLONG,
  ];

  public dimensions: Dimensions = {
    geometry: Geometry.ROUND,
    a: 1,
    b: 1,
    d: 1,
    l: 1,
  };

  public calculationError$ = this.calculationService.calculationResult$.pipe(map((r) => r?.error));

  public recommendedFlatPunchName$: Observable<string | undefined>;
  public recommendedWhisperPunchName$: Observable<string | undefined>;
  public recommendedRoofPunchName$: Observable<string | undefined>;
  public recommendedFlatDieName$: Observable<string | undefined>;
  public recommendedWhisperDieName$: Observable<string | undefined>;
  public recommendedRoofDieName$: Observable<string | undefined>;

  constructor(
    public translations: TranslationHelper,
    public calculationService: PunchingForceCalculationService,
    private translateService: TranslateService,
  ) {
    for (const material of Object.values(Material)) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }

    this.recommendedFlatPunchName$ = this.calculationService.calculationResult$.pipe(
      map((r) => {
        if (r?.flatRecommendedPunch) {
          const punchName = punchSizeToTranslation(
            r.flatRecommendedPunch,
            translations,
            translateService,
          );
          return punchName;
        } else return '';
      }),
    );

    this.recommendedFlatDieName$ = this.calculationService.calculationResult$.pipe(
      map((r) => {
        if (r?.flatRecommendedDie) {
          const dieName = dieSizeToTranslation(
            r.flatRecommendedDie,
            translations,
            translateService,
          );
          return dieName;
        } else return '';
      }),
    );

    this.recommendedWhisperPunchName$ = this.calculationService.calculationResult$.pipe(
      map((r) => {
        if (r?.whisperRecommendedPunch) {
          const punchName = punchSizeToTranslation(
            r.whisperRecommendedPunch,
            translations,
            translateService,
          );
          return punchName;
        } else return '';
      }),
    );

    this.recommendedWhisperDieName$ = this.calculationService.calculationResult$.pipe(
      map((r) => {
        if (r?.whisperRecommendedDie) {
          const dieName = dieSizeToTranslation(
            r.whisperRecommendedDie,
            translations,
            translateService,
          );
          return dieName;
        } else return '';
      }),
    );

    this.recommendedRoofPunchName$ = this.calculationService.calculationResult$.pipe(
      map((r) => {
        if (r?.roofRecommendedPunch) {
          const punchName = punchSizeToTranslation(
            r.roofRecommendedPunch,
            translations,
            translateService,
          );
          return punchName;
        } else return '';
      }),
    );

    this.recommendedRoofDieName$ = this.calculationService.calculationResult$.pipe(
      map((r) => {
        if (r?.roofRecommendedDie) {
          const dieName = dieSizeToTranslation(
            r.roofRecommendedDie,
            translations,
            translateService,
          );
          return dieName;
        } else return '';
      }),
    );

    this.updateSheetThicknesses(this.selectedMachine);
  }

  ngOnInit(): void {}

  public selectMachine(machineType: PunchingMachineType): void {
    this.selectedMachine = machineType;
    this.updateSheetThicknesses(machineType);
  }

  public selectedMachineName(): string {
    return this.machines.find((m) => m.type === this.selectedMachine)?.name ?? 'unknown';
  }

  public selectedMaterialName(): string {
    return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedMachine,
      this.selectedMaterial,
      this.selectedSheetThickness,
      this.dimensions,
    );
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }

  private updateSheetThicknesses(machineType: PunchingMachineType): void {
    const maxSheetThickness = MAX_SHEET_THICKNESSES.get(machineType);

    if (maxSheetThickness != undefined) {
      let sheetThicknesses: number[] = [];

      this.allSheetThicknesses.forEach((st) => {
        if (st <= maxSheetThickness) {
          sheetThicknesses.push(st);
        }
      });

      const arraysEqual = this.arraysEqual(this.availableSheetThicknesses, sheetThicknesses);

      if (!arraysEqual) {
        this.availableSheetThicknesses = sheetThicknesses;
        if (!this.availableSheetThicknesses.find((st) => st === this.selectedSheetThickness)) {
          this.selectedSheetThickness = this.availableSheetThicknesses[0];
        }
      }
    }
  }

  private arraysEqual<T>(a: T[], b: T[]): boolean {
    if (!a || !b) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }
}
