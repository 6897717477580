import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Material } from '../model/material';
import { Dimensions } from '../model/dimensions';
import { CuttingClearanceCalculationResult } from '../model/cutting-clearance-calculation-result';
import { Geometry } from '../model/geometry';
import { DimensionsValidatorService } from './dimensions-validator.service';

@Injectable({
  providedIn: 'root',
})
export class CuttingClearanceCalculationService {
  private calculationResult: BehaviorSubject<CuttingClearanceCalculationResult | undefined> =
    new BehaviorSubject<CuttingClearanceCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  constructor(private dimensionsValidator: DimensionsValidatorService) {}

  public calculate(material: Material, sheetThicknesses: number, dimensions: Dimensions): void {
    const dimensionsError = this.dimensionsValidator.validate(dimensions);
    if (dimensionsError) {
      this.calculationResult.next({
        label: 'undefined',
        error: dimensionsError,
      });
      return;
    }
    const materialCoefficient = material === Material.ALUMINUM ? 0.1 : 0.2;
    const result: CuttingClearanceCalculationResult = { label: 'Success' };
    switch (dimensions.geometry) {
      case Geometry.ROUND:
        result.d = materialCoefficient * sheetThicknesses + +dimensions.d;
        break;
      case Geometry.SQUARE:
        result.a = materialCoefficient * sheetThicknesses + +dimensions.a;
        break;
      case Geometry.RECTANGLE:
        result.a = materialCoefficient * sheetThicknesses + +dimensions.a;
        result.b = materialCoefficient * sheetThicknesses + +dimensions.b;
        break;
      case Geometry.OBLONG:
        result.a = materialCoefficient * sheetThicknesses + +dimensions.a;
        result.l = materialCoefficient * sheetThicknesses + +dimensions.l;
        break;
    }

    this.calculationResult.next(result);
  }

  public clearResult(): void {
    this.calculationResult.next(undefined);
  }
}
