<div class="input-container">
  <div class="stacked">

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.PUNCH_SIZE | translate }}
    </span>
    <xui-dropdown #sizeDropdown class="drop-down" [label]="selectedSizeName()"
                  data-ngtx="stripper-selection:size">
      <xui-dropdown-item
        *ngFor="let size of sizes"
        (click)="selectedSize = size.id; clearResult(); sizeDropdown.close()"
      >
        {{ size?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.DIMENSIONS | translate }}
    </span>
    <lsb-dimensions [(dimensions)]="dimensions" [geometries]="availableGeometries" (change)="clearResult()"></lsb-dimensions>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.SHEAR | translate }}
    </span>
    <xui-dropdown #shearDropdown class="drop-down" [label]="selectedShearName()"
                  data-ngtx="stripper-selection:shear">
      <xui-dropdown-item
        *ngFor="let shear of shears"
        (click)="shearChanged(shear.id); shearDropdown.close()"
      >
        {{ shear?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

    <div class="input-pox">
      <xui-input-field
        data-ngtx="stripper-selection:tool-length"
        label="{{ translations.CALCULATIONS.INPUT_LABELS.TOOL_LENGTH | translate }}"
        [(text)]="toolLengthVal.value"
        (change)="clearResult()"
      >
        <xui-value
          #toolLengthVal
          lsbValidateRequired
          [(binding)]="toolLength"
          [fallbackValue]="toolLength"
        >
          <xui-length-converter
            metricTargetUnit="mm"
            imperialTargetUnit="inch"
          ></xui-length-converter>
          <xui-number-formatter [maxDecimals]="2" [unit]="toolLengthVal.unit"></xui-number-formatter>
        </xui-value>
      </xui-input-field>
    </div>

    <span class="dropdown-label">
      {{ translations.CALCULATIONS.INPUT_LABELS.SHEET_THICKNESS | translate }}
    </span>
    <xui-dropdown #sheetThicknessDropDown class="drop-down" [label]="selectedSheetThicknessInCmOrInch.value"
                  data-ngtx="stripper-selection:sheet-thickness">

      <xui-value #selectedSheetThicknessInCmOrInch  [binding]="selectedSheetThickness">
        <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
        <xui-number-formatter [maxDecimals]="2" [unit]="selectedSheetThicknessInCmOrInch.unit"></xui-number-formatter>
      </xui-value>
      <xui-dropdown-item
        *ngFor="let sheetThickness of sheetThicknesses"
        (click)="selectedSheetThickness = sheetThickness; clearResult(); sheetThicknessDropDown.close()"
      >
        <xui-value #sheetThicknessInCentimeterOrInch [binding]="sheetThickness">
          <xui-length-converter metricTargetUnit="mm" imperialTargetUnit="inch"></xui-length-converter>
          <xui-number-formatter [maxDecimals]="2" [unit]="sheetThicknessInCentimeterOrInch.unit"></xui-number-formatter>
        </xui-value>
        {{ sheetThicknessInCentimeterOrInch.value }}
      </xui-dropdown-item>
    </xui-dropdown>


    <span class="calculationError" *ngIf="calculationError$ | async as error">
      {{error}}
    </span>

    <xui-button
      class="calculate-button"
      xuiClickUsageTracking="{{ uiElementIds.CALCULATE_STRIPPING_SELECTION }}"
      (click)="calculate()"
    >
      {{ translations.CALCULATIONS.INPUT_LABELS.CALCULATE | translate }}
    </xui-button>
  </div>
  <div class="results-container" >
    <div class="stacked" *ngIf="calculationResult$ | async as calculationResult">
      <span class="calculationResultTitle" *ngIf="!calculationResult.error">
        {{ translations.CALCULATIONS.TYPES.STRIPPER_SELECTION | translate }}
      </span>
      <ng-container *ngIf="!calculationResult.error && calculationResult.a">
        <xui-caption-label
          [caption]="'a'"
          [label]="aValue.value"
          class="dimensionResultArea"
        >
          <xui-value #aValue [binding]="calculationResult.a">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="aValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="!calculationResult.error && calculationResult.b">
        <xui-caption-label
          [caption]="'b'"
          [label]="bValue.value"
          class="dimensionResultArea"
        >
          <xui-value #bValue [binding]="calculationResult.b">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="bValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="!calculationResult.error && calculationResult.d">
        <xui-caption-label
          [caption]="'d'"
          [label]="dValue.value"
          class="dimensionResultArea"
        >
          <xui-value #dValue [binding]="calculationResult.d">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="dValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="!calculationResult.error && calculationResult.l">
        <xui-caption-label
          [caption]="'l'"
          [label]="lValue.value"
          class="dimensionResultArea"
        >
          <xui-value #lValue [binding]="calculationResult.l">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="lValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="!calculationResult.error && calculationResult.outerDiameter">
        <xui-caption-label
          [caption]="translations.CALCULATIONS.OUTER_DIAMETER  | translate"
          [label]="outerDiameterValue.value"
          class="dimensionResultArea"
        >
          <xui-value #outerDiameterValue [binding]="calculationResult.outerDiameter">
            <xui-length-converter
              metricTargetUnit="mm"
              imperialTargetUnit="inch"
            ></xui-length-converter>
            <xui-number-formatter [maxDecimals]="2" [unit]="outerDiameterValue.unit"></xui-number-formatter>
          </xui-value>
        </xui-caption-label>
      </ng-container>

      <ng-container *ngIf="calculationResult.notPermitted">
        <xui-caption-label
          [label]="'Not permitted'"
          class="dimensionResultArea"
        >
        </xui-caption-label>
      </ng-container>

      <span class="calculationWarning" *ngIf="calculationResult.warningMessage">
      {{calculationResult.warningMessage}}
    </span>

    </div>
  </div>
</div>
