import { StripperSelectionType } from '../model/stripper-selection-type';
import { ShearFactor } from '../model/shear-factor';

export const STRIPPER_SELECTIONS: StripperSelectionType[] = [
  // 37.8-36.8, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 37.8-36.8, needle punch, 3.01 - 6
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 37.8-36.8, needle punch, 6.01 - 10.5
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 37.8-36.8, punch, 1 - 5.99
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 37.8-36.8, punch, 6 - 10.5
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 36.8,
    toolLengthMax: 37.8,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  // 36.7-35.8, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 36.7-35.8, needle punch, 3.01 - 6
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 36.7-35.8, needle punch, 6.01 - 10.5
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 36.7-35.8, punch, 1 - 5.99
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 36.7-35.8, punch, 6 - 10.5
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 35.8,
    toolLengthMax: 36.7,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  // 35.7-34.8, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 35.7-34.8, needle punch, 3.01-6
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 35.7-34.8, needle punch, 6.01-10.5
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 5,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 35.7-34.8, punch, 1-5.99
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 3,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 35.7-34.8, punch, 6-10.5
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 5,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 34.8,
    toolLengthMax: 35.7,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },

  // 34.7-33.8, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 34.7-33.8, needle punch, 3.01-6
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 34.7-33.8, needle punch, 6.01-10.5
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 5,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 34.7-33.8, punch, 1-5.99
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 3,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 34.7-33.8, punch, 6-10.5
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 5,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 33.8,
    toolLengthMax: 34.7,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  // 33.7-32.8, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 33.7-32.8, needle punch, 3.01-6
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 33.7-32.8, needle punch, 6.01-10.5
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 5,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 33.7-32.8, punch, 1-5.99
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 2,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 3,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 33.7-32.8, punch, 6-10.5
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 5,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  // 33.7-32.8, punch, 10.51-30
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  // 33.7-32.8, punch, 30.01-40
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  // 33.7-32.8, punch, 40.01-50.8
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 6.4,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 32.8,
    toolLengthMax: 33.7,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  // 32.7-31.8, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 32.7-31.8, needle punch, 3.01-6
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 3,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 32.7-31.8, needle punch, 6.01-10.5
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 3,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 31,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 31,
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 32.7-31.8, punch, 1-5.99
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 1,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 2,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 3,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 32.7-31.8, punch, 6-10.5
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 3,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  // 32.7-31.8, punch, 10.51-30
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  // 32.7-31.8, punch, 30.01-40
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  // 32.7-31.8, punch, 40.01-50.8
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 5,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 6.4,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.FLAT,
    tollLengthMin: 31.8,
    toolLengthMax: 32.7,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  // 37.3-36.3, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 37.3-36.3, needle punch, 3.01-6
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 37.3-36.3, needle punch, 6.01-10.5
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 5,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 37.3-36.3, punch, 1-5.99
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 3,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 37.3-36.3, punch, 6-10.5
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 5,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 36.3,
    toolLengthMax: 37.3,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  // 36.2-35.3, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 36.2-35.3, needle punch, 3.01-6
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 36.2-35.3, needle punch, 6.01-10.5
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 5,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 36.2-35.3, punch, 1-5.99
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 2,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 3,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 36.2-35.3, punch, 6-10.5
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 5,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  // 36.2-35.3, punch, 10.51-30
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  // 36.2-35.3, punch, 30.01-40
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  // 36.2-35.3, punch, 40.01-50.8
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 6.4,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 35.3,
    toolLengthMax: 36.2,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  // 35.2-34.3, needle punch, up to 3
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 1,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 2,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 3,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 0,
    outerDiameterMax: 3,
    result: 'not permitted',
  },
  // 35.2-34.3, needle punch, 3.01-6
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 3,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 7,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 3.01,
    outerDiameterMax: 6,
    result: 'not permitted',
  },
  // 35.2-34.3, needle punch, 6.01-10.5
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 3,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 12,
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  {
    punchSize: 0,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 6.01,
    outerDiameterMax: 10.5,
    result: 'not permitted',
  },
  // 35.2-34.3, punch, 1-5.99
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 1,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 2,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 3,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 1,
    outerDiameterMax: 5.99,
    result: 'not permitted',
  },
  // 35.2-34.3, punch, 6-10.5
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 3,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 14,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 6,
    outerDiameterMax: 10.5,
    result: 31,
  },
  // 35.2-34.3, punch, 10.51-30
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 10.51,
    outerDiameterMax: 30,
    result: 31,
  },
  // 35.2-34.3, punch, 30.01-40
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 30.01,
    outerDiameterMax: 40,
    result: 41,
  },
  // 35.2-34.3, punch, 40.01-50.8
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 5,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 6.4,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
  {
    punchSize: 1,
    shear: ShearFactor.WHISPER,
    tollLengthMin: 34.3,
    toolLengthMax: 35.2,
    sheetThickness: 8,
    outerDiameterMin: 40.01,
    outerDiameterMax: 50.8,
    result: 52,
  },
];
