import { TranslateService } from '@ngx-translate/core';
import { TranslationHelper } from '../../shared/helpers';
import { CalculationType } from '../model/calculation-type';
import { DieSize } from '../model/die-size';
import { Geometry } from '../model/geometry';
import { Material } from '../model/material';
import { PunchSize } from '../model/punch-size';
import { ApplicationType } from '../model/application-type';
import { ShearFactor } from '../model/shear-factor';

export const calculationTypeToTranslation = (
  calculationType: CalculationType,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (calculationType) {
    case CalculationType.PUNCHING_FORCE:
      return translateService.instant(translations.CALCULATIONS.TYPES.PUNCHING_FORCE);
    case CalculationType.CUTTING_CLEARANCE:
      return translateService.instant(translations.CALCULATIONS.TYPES.CUTTING_CLEARANCE);
    case CalculationType.PRE_PUNCHING_DIAMETER:
      return translateService.instant(translations.CALCULATIONS.TYPES.PRE_PUNCHING_DIAMETER);
    case CalculationType.MAXIMUM_EDGE_LENGTH:
      return translateService.instant(translations.CALCULATIONS.TYPES.MAXIMUM_EDGE_LENGTH);
    case CalculationType.STRIPPER_SELECTION:
      return translateService.instant(translations.CALCULATIONS.TYPES.STRIPPER_SELECTION);
    case CalculationType.SHEET_THICKNESS:
      return translateService.instant(translations.CALCULATIONS.TYPES.SHEET_THICKNESS);
    case CalculationType.SHEET_WEIGHT:
      return translateService.instant(translations.CALCULATIONS.TYPES.SHEET_WEIGHT);
  }
};

export const materialToTranslation = (
  material: Material,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (material) {
    case Material.STEEL:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.STEEL);
    case Material.STAINLESS_STEEL:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.STAINLESS_STEEL);
    case Material.ALUMINUM:
      return translateService.instant(translations.CALCULATIONS.MATERIAL.ALUMINUM);
  }
};

export const geometryToTranslation = (
  geometry: Geometry,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (geometry) {
    case Geometry.ROUND:
      return translateService.instant(translations.CALCULATIONS.GEOMETRY.ROUND);
    case Geometry.SQUARE:
      return translateService.instant(translations.CALCULATIONS.GEOMETRY.SQUARE);
    case Geometry.RECTANGLE:
      return translateService.instant(translations.CALCULATIONS.GEOMETRY.RECTANGLE);
    case Geometry.OBLONG:
      return translateService.instant(translations.CALCULATIONS.GEOMETRY.OBLONG);
  }
};

export const punchSizeToTranslation = (
  punch: PunchSize,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (punch) {
    case PunchSize.MULTI_TOOL_5_STATION:
      return translateService.instant(translations.CALCULATIONS.PUNCH_SIZE.MULTI_TOOL_5_STATION);
    case PunchSize.MULTI_TOOL_10_STATION:
      return translateService.instant(translations.CALCULATIONS.PUNCH_SIZE.MULTI_TOOL_10_STATION);
    case PunchSize.SIZE_0_6MM:
      return translateService.instant(translations.CALCULATIONS.PUNCH_SIZE.SIZE_0_6MM);
    case PunchSize.SIZE_0_10POINT5MM:
      return translateService.instant(translations.CALCULATIONS.PUNCH_SIZE.SIZE_0_10POINT5MM);
    case PunchSize.SIZE_1:
      return translateService.instant(translations.CALCULATIONS.PUNCH_SIZE.SIZE_1);
    case PunchSize.SIZE_2:
      return translateService.instant(translations.CALCULATIONS.PUNCH_SIZE.SIZE_2);
    case PunchSize.SIZE_2_ENFORCED_SHOULDER:
      return translateService.instant(
        translations.CALCULATIONS.PUNCH_SIZE.SIZE_2_ENFORCED_SHOULDER,
      );
    case PunchSize.NOT_POSSIBLE:
      return translateService.instant(translations.CALCULATIONS.PUNCH_SIZE.NOT_POSSIBLE);
  }
};

export const dieSizeToTranslation = (
  die: DieSize,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (die) {
    case DieSize.SIZE_1:
      return translateService.instant(translations.CALCULATIONS.DIE_SIZE.SIZE_1);
    case DieSize.SIZE_2:
      return translateService.instant(translations.CALCULATIONS.DIE_SIZE.SIZE_2);
    case DieSize.SIZE_2_ENFORCED:
      return translateService.instant(translations.CALCULATIONS.DIE_SIZE.SIZE_2_ENFORCED);
    case DieSize.NOT_POSSIBLE:
      return translateService.instant(translations.CALCULATIONS.DIE_SIZE.NOT_POSSIBLE);
  }
};

export const applicationTypeToTranslation = (
  application: ApplicationType,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (application) {
    case ApplicationType.COUNTERSINK_UPPER:
      return translateService.instant(translations.CALCULATIONS.APPLICATION.COUNTERSINK_UPPER);
    case ApplicationType.COUNTERSINK_UNDER:
      return translateService.instant(translations.CALCULATIONS.APPLICATION.COUNTERSINK_UNDER);
    case ApplicationType.TAPPING_EVEN:
      return translateService.instant(translations.CALCULATIONS.APPLICATION.TAPPING_EVEN);
    case ApplicationType.EXTRUSION:
      return translateService.instant(translations.CALCULATIONS.APPLICATION.EXTRUSION);
  }
};

export const stripperSelectionSizeToTranslation = (
  size: number,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  const sizeWord = translateService.instant(translations.CALCULATIONS.INPUT_LABELS.SIZE);
  return `${sizeWord} ${size}`;
};

export const shearToTranslation = (
  shear: ShearFactor,
  translations: TranslationHelper,
  translateService: TranslateService,
): string => {
  switch (shear) {
    case ShearFactor.FLAT:
      return translateService.instant(translations.CALCULATIONS.SHEAR_FACTOR.FLAT);
    case ShearFactor.WHISPER:
      return translateService.instant(translations.CALCULATIONS.SHEAR_FACTOR.WHISPER);
    case ShearFactor.ROOF:
      return translateService.instant(translations.CALCULATIONS.SHEAR_FACTOR.ROOF);
  }
};
