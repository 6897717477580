// NOTE: Punching machines list is static
export enum PunchingMachineType {
  TRU_PUNCH_1000_S05 = 'TRU_PUNCH_1000_S05',
  TRU_PUNCH_1000_S19 = 'TRU_PUNCH_1000_S19',
  TRU_PUNCH_2000_S18 = 'TRU_PUNCH_2000_S18',
  TRU_PUNCH_3000_S11 = 'TRU_PUNCH_3000_S11',
  TRU_PUNCH_3000_S20 = 'TRU_PUNCH_3000_S20',
  TRU_PUNCH_5000_S01 = 'TRU_PUNCH_5000_S01',
  TRU_PUNCH_5000_S10 = 'TRU_PUNCH_5000_S10',
  TRU_PUNCH_5000_S12 = 'TRU_PUNCH_5000_S12',
  TRU_MATIC_1000_K07 = 'TRU_MATIC_1000_K07',
  TRU_MATIC_3000_K04 = 'TRU_MATIC_3000_K04',
  TRU_MATIC_3000_K09 = 'TRU_MATIC_3000_K09',
  TRU_MATIC_6000_K01 = 'TRU_MATIC_6000_K01',
  TRU_MATIC_6000_K05 = 'TRU_MATIC_6000_K05',
  TRU_MATIC_6000_K06 = 'TRU_MATIC_6000_K06',
  TRU_MATIC_7000_K02 = 'TRU_MATIC_7000_K02',
  TRU_MATIC_7000_K08 = 'TRU_MATIC_7000_K08',
}

export interface PunchingMachine {
  type: PunchingMachineType;
  name: string;
}
