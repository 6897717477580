import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelper } from '../../shared/helpers';
import { geometryToTranslation } from '../helpers/enum-to-translation';
import { Dimensions } from '../model/dimensions';
import { DropdownItemValue } from '../model/dropdown-item-value';
import { Geometry } from '../model/geometry';

@Component({
  selector: 'lsb-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss'],
})
export class DimensionsComponent implements OnInit {
  @Input()
  public geometries: Geometry[] = [];
  @Input()
  public dimensions: Dimensions;
  @Input()
  public reducedDimensions: boolean;
  @Output()
  public dimensionsChange = new EventEmitter<Dimensions>();
  @Output()
  public change = new EventEmitter<void>();

  // this enables the use of enum values in the template.
  public geometryValues = Geometry;

  public geometriesList: DropdownItemValue<Geometry>[] = [];
  public selectedGeometry: Geometry;

  constructor(public translations: TranslationHelper, private translateService: TranslateService) {}

  ngOnInit(): void {
    for (const geometry of this.geometries) {
      this.geometriesList.push({
        id: geometry,
        name: geometryToTranslation(geometry, this.translations, this.translateService),
      });
    }
  }

  public selectedGeometryName(): string {
    this.dimensionsChange.emit(this.dimensions);
    return this.geometriesList.find((m) => m.id === this.dimensions?.geometry)?.name ?? 'unknown';
  }
}
