import { Component, OnInit } from '@angular/core';
import { TranslationHelper } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { Geometry } from '../../model/geometry';
import { Dimensions } from '../../model/dimensions';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import {
  shearToTranslation,
  stripperSelectionSizeToTranslation,
} from '../../helpers/enum-to-translation';
import { ShearFactor } from '../../model/shear-factor';
import { StripperSelectionService } from '../../services/stripper-selection.service';
import { map } from 'rxjs/operators';

const DEFAULT_FLAT_SHARE_TOOL_LENGTH = 37.8;
const DEFAULT_WHISPER_SHARE_TOOL_LENGTH = 37.3;

@Component({
  selector: 'lsb-stripper-selection',
  templateUrl: './stripper-selection.component.html',
  styleUrls: ['./stripper-selection.component.scss'],
})
export class StripperSelectionComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public availableGeometries: Geometry[] = [
    Geometry.ROUND,
    Geometry.RECTANGLE,
    Geometry.SQUARE,
    Geometry.OBLONG,
  ];

  public dimensions: Dimensions = {
    geometry: Geometry.ROUND,
    a: 1,
    b: 1,
    d: 1,
    l: 1,
  };

  public sheetThicknesses: number[] = [1, 2, 3, 4, 5, 6, 6.4, 8];
  public selectedSheetThickness: number = this.sheetThicknesses[0];

  public sizes: DropdownItemValue<number>[] = [];
  public selectedSize = 0;

  public shears: DropdownItemValue<ShearFactor>[] = [];
  public selectedShear = ShearFactor.FLAT;

  public toolLength = DEFAULT_FLAT_SHARE_TOOL_LENGTH;

  public calculationResult$ = this.calculationService.calculationResult$;
  public calculationError$ = this.calculationService.calculationResult$.pipe(map((r) => r?.error));

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: StripperSelectionService,
  ) {
    for (let i = 0; i < 3; i++) {
      this.sizes.push({
        id: i,
        name: stripperSelectionSizeToTranslation(i, translations, translateService),
      });
    }
    this.shears.push({
      id: ShearFactor.FLAT,
      name: shearToTranslation(ShearFactor.FLAT, translations, translateService),
    });
    this.shears.push({
      id: ShearFactor.WHISPER,
      name: shearToTranslation(ShearFactor.WHISPER, translations, translateService),
    });
  }

  ngOnInit(): void {}

  public selectedSizeName(): string {
    return this.sizes.find((m) => m.id === this.selectedSize)?.name ?? 'unknown';
  }

  public selectedShearName(): string {
    return this.shears.find((s) => s.id === this.selectedShear)?.name ?? 'unknown';
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedSize,
      this.dimensions,
      this.selectedShear,
      this.toolLength,
      this.selectedSheetThickness,
    );
  }

  public shearChanged(id: ShearFactor) {
    this.selectedShear = id;
    this.clearResult();
    this.toolLength =
      id === ShearFactor.FLAT ? DEFAULT_FLAT_SHARE_TOOL_LENGTH : DEFAULT_WHISPER_SHARE_TOOL_LENGTH;
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }
}
