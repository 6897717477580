import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsageTrackingModule } from '@trumpf-xguide/xguide';
import { SharedModule } from '../shared/shared.module';
import { CalculationsRoutingModule } from './calculations-routing.module';
import { DimensionsComponent } from './components/dimensions.component';
import { CalculationsComponent } from './views/calculations/calculations.component';
import { CuttingClearanceComponent } from './views/cutting-clearance/cutting-clearance.component';
import { MaximumEdgeLengthComponent } from './views/maximum-edge-length/maximum-edge-length.component';
import { PrePunchingDiameterComponent } from './views/pre-punching-diameter/pre-punching-diameter.component';
import { PunchingForceComponent } from './views/punching-force/punching-force.component';
import { SheetThicknessComponent } from './views/sheet-thickness/sheet-thickness.component';
import { SheetWeightComponent } from './views/sheet-weight/sheet-weight.component';
import { StripperSelectionComponent } from './views/stripper-selection/stripper-selection.component';

@NgModule({
  declarations: [
    CalculationsComponent,
    PunchingForceComponent,
    DimensionsComponent,
    CuttingClearanceComponent,
    MaximumEdgeLengthComponent,
    PrePunchingDiameterComponent,
    SheetThicknessComponent,
    SheetWeightComponent,
    StripperSelectionComponent,
  ],
  imports: [CalculationsRoutingModule, CommonModule, SharedModule, UsageTrackingModule.forChild()],
})
export class CalculationsModule {}
