<xui-sub-menu class="menu">
  <xui-sub-menu-tab
    *ngFor="let calculationTab of calculationTypes"
    xuiClickUsageTracking="{{ uiElementIds.CALCULATION_CATEGORY_ALL }}"
    [specificElementIdentifier]="calculationTab.type"
    [routerLink]="calculationTab.route"
    [label]="calculationTab.translation"
    [selected]="calculationTab.type === selectedCalculationType"
    (click)="selectCalculationType(calculationTab.type)"
  ></xui-sub-menu-tab>
</xui-sub-menu>

<div class="content">
  <router-outlet></router-outlet>
</div>

