import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalculationsComponent } from './views/calculations/calculations.component';
import { CuttingClearanceComponent } from './views/cutting-clearance/cutting-clearance.component';
import { MaximumEdgeLengthComponent } from './views/maximum-edge-length/maximum-edge-length.component';
import { PrePunchingDiameterComponent } from './views/pre-punching-diameter/pre-punching-diameter.component';
import { PunchingForceComponent } from './views/punching-force/punching-force.component';
import { SheetThicknessComponent } from './views/sheet-thickness/sheet-thickness.component';
import { SheetWeightComponent } from './views/sheet-weight/sheet-weight.component';
import { StripperSelectionComponent } from './views/stripper-selection/stripper-selection.component';

const routes: Routes = [
  {
    path: 'calculations',
    component: CalculationsComponent,
    children: [
      {
        path: '',
        redirectTo: 'punching-force',
        pathMatch: 'full',
      },
      { path: 'punching-force', component: PunchingForceComponent },
      { path: 'cutting-clearance', component: CuttingClearanceComponent },
      { path: 'pre-punching-diameter', component: PrePunchingDiameterComponent },
      { path: 'maximum-edge-length', component: MaximumEdgeLengthComponent },
      { path: 'sheet-thickness', component: SheetThicknessComponent },
      { path: 'sheet-weight', component: SheetWeightComponent },
      { path: 'stripper-selection', component: StripperSelectionComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CalculationsRoutingModule {}
