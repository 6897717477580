import { Component } from '@angular/core';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { TranslationHelper } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { materialToTranslation } from '../../helpers/enum-to-translation';
import { Geometry } from '../../model/geometry';
import { Dimensions } from '../../model/dimensions';
import { CuttingClearanceCalculationService } from '../../services/cutting-clearance-calculation.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lsb-cutting-clearance',
  templateUrl: './cutting-clearance.component.html',
  styleUrls: ['./cutting-clearance.component.scss'],
})
export class CuttingClearanceComponent {
  public readonly uiElementIds = UiElementIds;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STEEL;

  // NOTE: If thicknesses are the same for every screen, it is better to move it to common constant
  public sheetThicknesses: number[] = [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.5, 4, 5, 6, 8];
  public selectedSheetThickness: number = this.sheetThicknesses[0];

  public availableGeometries: Geometry[] = [
    Geometry.ROUND,
    Geometry.RECTANGLE,
    Geometry.SQUARE,
    Geometry.OBLONG,
  ];

  public dimensions: Dimensions = {
    geometry: Geometry.ROUND,
    a: 1,
    b: 1,
    d: 1,
    l: 1,
  };

  public calculationResult$ = this.calculationService.calculationResult$;
  public calculationError$ = this.calculationResult$.pipe(map((r) => r?.error));

  constructor(
    public translations: TranslationHelper,
    private translateService: TranslateService,
    private calculationService: CuttingClearanceCalculationService,
  ) {
    for (const material of Object.values(Material)) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }
  }

  public selectedMaterialName(): string {
    return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
  }

  public calculate(): void {
    this.calculationService.calculate(
      this.selectedMaterial,
      this.selectedSheetThickness,
      this.dimensions,
    );
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }
}
