import { Injectable } from '@angular/core';
import { Material } from '../model/material';
import { BehaviorSubject } from 'rxjs';
import { PrePunchingDiameterResult } from '../model/pre-punching-diameter-result';
import { ApplicationType } from '../model/application-type';
import { ThreadSizeType } from '../model/pre-punching-diameter-type';
import { PRE_PUNCHING_DIAMETERS } from '../data/pre-punching-diameters';

@Injectable({
  providedIn: 'root',
})
export class PrePunchingDiameterCalculationService {
  private calculationResult: BehaviorSubject<PrePunchingDiameterResult | undefined> =
    new BehaviorSubject<PrePunchingDiameterResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  private threadSizes: BehaviorSubject<ThreadSizeType[]> = new BehaviorSubject<ThreadSizeType[]>(
    [],
  );
  public threadSizes$ = this.threadSizes.asObservable();

  private thicknesses: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public thicknesses$ = this.thicknesses.asObservable();

  public calculate(
    application: ApplicationType,
    material: Material,
    threadSize: ThreadSizeType,
    sheetThicknesses: number,
  ): void {
    const applicationToFilter = this.chooseApplicationToFilter(application);
    const diameter = PRE_PUNCHING_DIAMETERS.find(
      (d) =>
        d.application === applicationToFilter &&
        d.threadSize === threadSize &&
        d.thickness === sheetThicknesses,
    )?.diameters.get(material);
    this.calculationResult.next({ label: 'Hello there', diameter });
  }

  /**
   * Filters thread sizes list depending on selected application type
   * @param application application type to filter
   * @param material material material to filter
   */
  public applicationOrMaterialChanged(application: ApplicationType, material: Material): void {
    const applicationToFilter = this.chooseApplicationToFilter(application);
    const sizes = [
      ...new Set(
        PRE_PUNCHING_DIAMETERS.filter(
          (d) => d.application === applicationToFilter && d.diameters.get(material) !== undefined,
        ).map((d) => d.threadSize),
      ),
    ];
    this.threadSizes.next(sizes);
  }

  /**
   * Filters sheet thicknesses list depending on selected application type, thread size, and material
   * @param application application type to filter
   * @param material material to filter
   * @param threadSize thread size to filter
   */
  public threadSizeOrMaterialChanged(
    application: ApplicationType,
    material: Material,
    threadSize: ThreadSizeType,
  ): void {
    const applicationToFilter = this.chooseApplicationToFilter(application);
    const thicknesses = [
      ...new Set(
        PRE_PUNCHING_DIAMETERS.filter(
          (d) =>
            d.application === applicationToFilter &&
            d.threadSize === threadSize &&
            d.diameters.get(material) !== undefined,
        ).map((d) => d.thickness),
      ),
    ];
    this.thicknesses.next(thicknesses);
  }

  public clearResult(): void {
    this.calculationResult.next(undefined);
  }

  // Countersink tool for upper and underside sheet applications has similar set of parameters
  // For simplicity, constant PRE_PUNCHING_DIAMETERS has only one of them, that's why for the filter we convert one into another
  private chooseApplicationToFilter(application: ApplicationType): ApplicationType {
    return application === ApplicationType.COUNTERSINK_UPPER
      ? ApplicationType.COUNTERSINK_UNDER
      : application;
  }
}
