import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { GAUGES } from '../../data/constants';
import { materialToTranslation } from '../../helpers/enum-to-translation';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Material } from '../../model/material';
import { SheetThicknessCalculationService } from '../../services/sheet-thickness-calculation.service';

@Component({
  selector: 'lsb-sheet-thickness',
  templateUrl: './sheet-thickness.component.html',
  styleUrls: ['../../styles/calculations-base-style.scss', './sheet-thickness.component.scss'],
})
export class SheetThicknessComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public materials: DropdownItemValue<Material>[] = [];
  public selectedMaterial: Material = Material.STEEL;

  public gauges: number[] = GAUGES;
  public selectedGauge: number = this.gauges[0];

  public calculationError$ = this.calculationService.calculationResult$.pipe(map((r) => r?.error));

  constructor(
    public translations: TranslationHelper,
    public calculationService: SheetThicknessCalculationService,
    private translateService: TranslateService,
  ) {
    for (const material of Object.values(Material)) {
      this.materials.push({
        id: material,
        name: materialToTranslation(material, translations, translateService),
      });
    }
  }

  ngOnInit(): void {}

  public calculate(): void {
    this.calculationService.calculate(this.selectedMaterial, this.selectedGauge);
  }

  public selectedMaterialName(): string {
    return this.materials.find((m) => m.id === this.selectedMaterial)?.name ?? 'unknown';
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }
}
