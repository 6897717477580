<xui-dropdown #geometryDropDown class="drop-down" [label]="selectedGeometryName()"
              data-ngtx="dimensions:geometry">
  <xui-dropdown-item
    *ngFor="let geometry of geometriesList"
    (click)="dimensions.geometry = geometry.id; dimensionsChange.emit(dimensions); change.emit(); geometryDropDown.close()"
  >
    {{ geometry?.name }}
  </xui-dropdown-item>
</xui-dropdown>

<xui-input-field
  *ngIf="(dimensions.geometry === geometryValues.RECTANGLE) || 
  ((dimensions.geometry === geometryValues.SQUARE) && !reducedDimensions)  || 
  ((dimensions.geometry === geometryValues.OBLONG) && !reducedDimensions)"
  data-ngtx="dimensions:a"
  label="a"
  [(text)]="aValue.value"
  (change)="dimensionsChange.emit(dimensions); change.emit();"
>
  <xui-value
    #aValue
    lsbValidateRequired
    [(binding)]="dimensions.a"
    [fallbackValue]="dimensions.a"
  >
    <xui-length-converter
      metricTargetUnit="mm"
      imperialTargetUnit="inch"
    ></xui-length-converter>
    <xui-number-formatter [maxDecimals]="2" [unit]="aValue.unit"></xui-number-formatter>
  </xui-value>
</xui-input-field>
<xui-input-field
  *ngIf="(dimensions.geometry === geometryValues.RECTANGLE) && !reducedDimensions"
  data-ngtx="dimensions:b"
  label="b"
  [(text)]="bValue.value"
  (change)="dimensionsChange.emit(dimensions); change.emit();"
>
  <xui-value
    #bValue
    lsbValidateRequired
    [(binding)]="dimensions.b"
    [fallbackValue]="dimensions.b"
  >
    <xui-length-converter
      metricTargetUnit="mm"
      imperialTargetUnit="inch"
    ></xui-length-converter>
    <xui-number-formatter [maxDecimals]="2" [unit]="bValue.unit"></xui-number-formatter>
  </xui-value>
</xui-input-field>
<xui-input-field
  *ngIf="(dimensions.geometry === geometryValues.ROUND) && !reducedDimensions"
  data-ngtx="dimensions:d"
  label="d"
  [(text)]="dValue.value"
  (change)="dimensionsChange.emit(dimensions); change.emit();"
>
  <xui-value
    #dValue
    lsbValidateRequired
    [(binding)]="dimensions.d"
    [fallbackValue]="dimensions.d"
  >
    <xui-length-converter
      metricTargetUnit="mm"
      imperialTargetUnit="inch"
    ></xui-length-converter>
    <xui-number-formatter [maxDecimals]="2" [unit]="dValue.unit"></xui-number-formatter>
  </xui-value>
</xui-input-field>
<xui-input-field
  *ngIf="(dimensions.geometry === geometryValues.OBLONG) && !reducedDimensions"
  data-ngtx="dimensions:l"
  label="l"
  [(text)]="lValue.value"
  (change)="dimensionsChange.emit(dimensions); change.emit();"
>
  <xui-value
    #lValue
    lsbValidateRequired
    [(binding)]="dimensions.l"
    [fallbackValue]="dimensions.l"
  >
    <xui-length-converter
      metricTargetUnit="mm"
      imperialTargetUnit="inch"
    ></xui-length-converter>
    <xui-number-formatter [maxDecimals]="2" [unit]="lValue.unit"></xui-number-formatter>
  </xui-value>
</xui-input-field>
<div class="dimensionImage">
  <img height="66px" width="48px" src="../../../assets/img/calculations/circle.jpg" *ngIf="dimensions.geometry === geometryValues.ROUND" >
  <img height="69px" width="118px" src="../../../assets/img/calculations/rectangle.jpg" *ngIf="dimensions.geometry === geometryValues.RECTANGLE" >
  <img height="60px" width="41px" src="../../../assets/img/calculations/square.jpg" *ngIf="dimensions.geometry === geometryValues.SQUARE" >
  <img height="51px" width="114px" src="../../../assets/img/calculations/oblong.jpg" *ngIf="dimensions.geometry === geometryValues.OBLONG" >
</div>
