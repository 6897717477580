import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StripperSelectionCalculationResult } from '../model/stripper-selection-calculation-result';
import { Dimensions } from '../model/dimensions';
import { ShearFactor } from '../model/shear-factor';
import { DimensionsValidatorService } from './dimensions-validator.service';
import { calculateOuterCircleDiameter, convertMillimeterTo } from '../helpers/calculations-helper';
import { STRIPPER_SELECTIONS } from '../data/stripper-selections';
import { AppSettings, SystemOfUnits } from '../../shared/types';
import { SettingsAccessor, SettingsService } from '@trumpf-xguide/xguide';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../../shared/constants';
import { Geometry } from '../model/geometry';
import { TranslationHelper } from '../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';

const TOOL_LENGTH_MIN = 31.3;
const TOOL_LENGTH_WHISPER_MIN = 34.3;
const TOOL_LENGTH_MAX = 37.8;

@Injectable({
  providedIn: 'root',
})
export class StripperSelectionService {
  private calculationResult: BehaviorSubject<StripperSelectionCalculationResult | undefined> =
    new BehaviorSubject<StripperSelectionCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  private settings: SettingsAccessor<AppSettings>;

  constructor(
    private dimensionsValidator: DimensionsValidatorService,
    private translations: TranslationHelper,
    private translateService: TranslateService,
    settingsService: SettingsService,
  ) {
    this.settings = settingsService.access<AppSettings>(
      SHARED_SETTINGS_NAMESPACE,
      DEFAULT_APP_SETTINGS,
    );
  }

  public calculate(
    size: number,
    dimensions: Dimensions,
    shear: ShearFactor,
    toolLength: number,
    sheetThickness: number,
  ): void {
    const toolLengthError = this.checkInterval(
      toolLength,
      shear,
      TOOL_LENGTH_MIN,
      TOOL_LENGTH_WHISPER_MIN,
      TOOL_LENGTH_MAX,
    );
    if (toolLengthError) {
      this.calculationResult.next({
        label: 'undefined',
        error: toolLengthError,
        notPermitted: false,
      });
      return;
    }

    const dimensionsError = this.dimensionsValidator.validate(dimensions);
    if (dimensionsError) {
      this.calculationResult.next({
        label: 'undefined',
        error: dimensionsError,
        notPermitted: false,
      });
      return;
    }

    const warningMessage =
      sheetThickness > 6.4 ? this.generateCheckMachineThicknessWarningMessage() : undefined;

    const outerCircleDiameter = calculateOuterCircleDiameter(dimensions);
    if (size < 1 && outerCircleDiameter > 10.5) {
      this.calculationResult.next({
        label: 'undefined',
        error: this.generateWrongSizeMessage(),
        notPermitted: false,
      });
      return;
    }
    const result = STRIPPER_SELECTIONS.find(
      (s) =>
        s.punchSize === (size === 2 ? 1 : size) &&
        s.sheetThickness === sheetThickness &&
        s.shear === shear &&
        s.tollLengthMin <= toolLength &&
        s.toolLengthMax >= toolLength &&
        s.outerDiameterMin <= outerCircleDiameter &&
        s.outerDiameterMax >= outerCircleDiameter,
    );
    if (result) {
      if (result.result === 'not permitted') {
        this.calculationResult.next({
          label: 'not permitted',
          notPermitted: true,
        });
        return;
      } else {
        this.calculationResult.next({
          label: 'constant result',
          notPermitted: false,
          d: result.result,
          outerDiameter: outerCircleDiameter,
          warningMessage,
        });
        return;
      }
    }

    switch (dimensions.geometry) {
      case Geometry.ROUND:
        this.calculationResult.next({
          label: 'calculated result',
          notPermitted: false,
          d: +dimensions.d + 1,
          outerDiameter: outerCircleDiameter,
          warningMessage,
        });
        break;
      case Geometry.RECTANGLE:
        this.calculationResult.next({
          label: 'calculated result',
          notPermitted: false,
          a: +dimensions.a + 1,
          b: +dimensions.b + 1,
          outerDiameter: outerCircleDiameter,
          warningMessage,
        });
        break;
      case Geometry.SQUARE:
        this.calculationResult.next({
          label: 'calculated result',
          notPermitted: false,
          a: +dimensions.a + 1,
          outerDiameter: outerCircleDiameter,
          warningMessage,
        });
        break;
      case Geometry.OBLONG:
        this.calculationResult.next({
          label: 'calculated result',
          notPermitted: false,
          a: +dimensions.a + 1,
          l: +dimensions.l + 1,
          outerDiameter: outerCircleDiameter,
          warningMessage,
        });
        break;
    }
  }

  public clearResult(): void {
    this.calculationResult.next(undefined);
  }

  private checkInterval(
    value: number,
    shear: ShearFactor,
    min: number,
    whisperMin: number,
    max: number,
  ): string | undefined {
    if (
      (shear === ShearFactor.WHISPER && value < whisperMin) ||
      (shear === ShearFactor.FLAT && value < min) ||
      value > max
    ) {
      const unitSystem = this.settings.get('unitSystem');
      return `Tool length must be between ${convertMillimeterTo(
        shear === ShearFactor.WHISPER ? whisperMin : min,
        unitSystem,
      )} and ${convertMillimeterTo(max, unitSystem)}${
        unitSystem === SystemOfUnits.Metric ? 'mm' : 'inch'
      }`;
    }
    return undefined;
  }

  private generateCheckMachineThicknessWarningMessage(): string {
    return this.translateService.instant(
      this.translations.CALCULATIONS.CHECK_SHEET_THICKNESS_MACHINE_WARNING,
    );
  }

  private generateWrongSizeMessage(): string {
    return this.translateService.instant(this.translations.CALCULATIONS.WRONG_SIZE_ERROR);
  }
}
