import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { punchSizeToTranslation } from '../../helpers/enum-to-translation';
import { Dimensions } from '../../model/dimensions';
import { DropdownItemValue } from '../../model/dropdown-item-value';
import { Geometry } from '../../model/geometry';
import { PunchSize } from '../../model/punch-size';
import { MaximumEdgeLengthCalculationService } from '../../services/maximum-edge-length-calculation.service';

@Component({
  selector: 'lsb-maximum-edge-length',
  templateUrl: './maximum-edge-length.component.html',
  styleUrls: ['./maximum-edge-length.component.scss'],
})
export class MaximumEdgeLengthComponent implements OnInit {
  public readonly uiElementIds = UiElementIds;

  public punchSizes: DropdownItemValue<PunchSize>[] = [];
  public selectedPunchSize: PunchSize = PunchSize.MULTI_TOOL_5_STATION;

  public availableGeometries: Geometry[] = [Geometry.RECTANGLE, Geometry.SQUARE, Geometry.OBLONG];

  public dimensions: Dimensions = {
    geometry: Geometry.RECTANGLE,
    a: 1,
    b: 1,
    d: 1,
    l: 1,
  };

  public calculationError$ = this.calculationService.calculationResult$.pipe(map((r) => r?.error));

  constructor(
    public translations: TranslationHelper,
    public calculationService: MaximumEdgeLengthCalculationService,
    private translateService: TranslateService,
  ) {
    for (const punchSize of Object.values(PunchSize)) {
      if (punchSize !== PunchSize.NOT_POSSIBLE) {
        this.punchSizes.push({
          id: punchSize,
          name: punchSizeToTranslation(punchSize, translations, translateService),
        });
      }
    }
  }

  ngOnInit(): void {}

  public selectedPunchSizeName(): string {
    return this.punchSizes.find((p) => p.id === this.selectedPunchSize)?.name ?? 'unknown';
  }

  public calculate(): void {
    this.calculationService.calculate(this.selectedPunchSize, this.dimensions);
  }

  public clearResult(): void {
    this.calculationService.clearResult();
  }
}
