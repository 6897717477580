import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dimensions } from '../model/dimensions';
import { Geometry } from '../model/geometry';
import { MaximumEdgeLengthCalculationResult } from '../model/maximum-edge-length-calculation-result';
import { PunchSize } from '../model/punch-size';

@Injectable({
  providedIn: 'root',
})
export class MaximumEdgeLengthCalculationService {
  // these values are set in mm
  private readonly maxOuterCircleDiameterValues: Map<PunchSize, number> = new Map<
    PunchSize,
    number
  >([
    [PunchSize.MULTI_TOOL_5_STATION, 16],
    [PunchSize.MULTI_TOOL_10_STATION, 10.5],
    [PunchSize.SIZE_0_6MM, 6],
    [PunchSize.SIZE_0_10POINT5MM, 10.5],
    [PunchSize.SIZE_1, 30],
    [PunchSize.SIZE_2, 76.2],
    [PunchSize.SIZE_2_ENFORCED_SHOULDER, 42],
  ]);

  // for oblong these values are the same for edge length and outer circle diameter
  private readonly oblongMaxEdgeLengthValues: Map<PunchSize, number> =
    this.maxOuterCircleDiameterValues;

  // these values are set in mm
  private readonly squareMaxEdgeLengthValues: Map<PunchSize, number> = new Map<PunchSize, number>([
    [PunchSize.MULTI_TOOL_5_STATION, 11.31],
    [PunchSize.MULTI_TOOL_10_STATION, 7.42],
    [PunchSize.SIZE_0_6MM, 4.24],
    [PunchSize.SIZE_0_10POINT5MM, 7.42],
    [PunchSize.SIZE_1, 21.21],
    [PunchSize.SIZE_2, 53.88],
    [PunchSize.SIZE_2_ENFORCED_SHOULDER, 29.7],
  ]);

  private calculationResult: BehaviorSubject<MaximumEdgeLengthCalculationResult | undefined> =
    new BehaviorSubject<MaximumEdgeLengthCalculationResult | undefined>(undefined);
  public calculationResult$ = this.calculationResult.asObservable();

  constructor() {}

  public calculate(punchSize: PunchSize, dimensions: Dimensions): void {
    let errorMessage = undefined;
    let maximumEdgeLength: number | undefined;
    let maximumOuterCircleDiameter: number | undefined;

    switch (dimensions.geometry) {
      case Geometry.OBLONG:
        maximumEdgeLength = this.oblongMaxEdgeLengthValues.get(punchSize);
        maximumOuterCircleDiameter = this.maxOuterCircleDiameterValues.get(punchSize);
        break;
      case Geometry.SQUARE:
        maximumEdgeLength = this.squareMaxEdgeLengthValues.get(punchSize);
        maximumOuterCircleDiameter = this.maxOuterCircleDiameterValues.get(punchSize);
        break;
      case Geometry.RECTANGLE:
        maximumOuterCircleDiameter = this.maxOuterCircleDiameterValues.get(punchSize) ?? 0;
        maximumEdgeLength = Math.sqrt(
          maximumOuterCircleDiameter * maximumOuterCircleDiameter - dimensions.a * dimensions.a,
        );

        if (dimensions.a > maximumOuterCircleDiameter) {
          errorMessage = `a must not be greater than ${maximumOuterCircleDiameter}`;
          maximumOuterCircleDiameter = undefined;
          maximumEdgeLength = undefined;
        }

        break;
      case Geometry.ROUND:
        errorMessage = 'Calculation not possible for round geometry.';
        break;
    }

    this.calculationResult.next({
      label: 'CalculationDone!',
      error: errorMessage,
      maximumEdgeLength: maximumEdgeLength,
      maximumOuterCircleDiameter: maximumOuterCircleDiameter,
    });
  }

  public clearResult(): void {
    this.calculationResult.next(undefined);
  }
}
